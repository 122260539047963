.page-content.--login
  padding-top: 90px
  padding-bottom: 150px
  +r(1199)
    padding-top: 40px
    padding-bottom: 40px
  +r(767)
    padding-top: 20px
    padding-bottom: 20px
  .container-xl
    max-width: 1408px
  .row
    & > *
      position: relative


.video-block
  display: block
  position: relative
  z-index: 2
  a
    display: block
    position: relative
    &::before
      content: ''
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%) scale(1)
      width: 72px
      height: 72px
      flex: 0 0 72px
      background-image: url(../img/play.svg)
      background-position: center
      background-size: contain
      +tr(0.3s)
    +h
      &::before
        transform: translate(-50%, -50%) scale(1.05)

    img
      width: 100%

.desc-block
  width: calc(100% + 60px)
  margin-top: 30px
  margin-left: -60px
  background: #FFFFFF
  border-radius: 8px
  padding: 60px 60px 50px 90px
  position: relative

  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 20px
  color: #5B6485

  +fz(14, 12)

  +r(1199)
    padding: 40px
    margin-left: 0
    width: 100%

  +r(767)
    padding: 20px
    margin-top: 15px

  &::after
    content: ''
    display: block
    width: 294px
    height: 205px
    background-image: url(../img/bg_login-page.svg)
    position: absolute
    left: -150px
    bottom: -100px
    pointer-events: none
    +r(1199)
      display: none

  h4
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485
    margin-top: 0
    margin-bottom: 15px
    +fz(18, 16)

  .button-group
    margin-top: 30px
    +r(767)
      margin-top: 15px
    & > *
      margin: 0 20px 10px 0
      +r(767)
        margin: 0 10px 10px 0
      &:nth-child(1),
      &:nth-child(2)
        +r(767)
          width: calc(50% - 10px)
      &:nth-child(3)
        +r(767)
          width: calc(100% - 10px)

