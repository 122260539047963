.widjet
  display: block
  position: relative
  background: #FFFFFF
  border-radius: 8px
  height: 100%

  .btn-icon,
  .action-group,
  .action-group-btn
    width: 30px
    height: 30px
    flex: 0 0 30px
    padding: 0

  &__box
    padding: 30px
    +r(1199)
      padding: 20px
    +r(767)
      padding: 15px
  &__head
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 20px
  &__title
    display: inline-flex
    align-items: center
    font-family: 'GothamPro'
    font-style: normal
    font-weight: normal
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    +ico()
      margin-left: 5px
    +r(767)
      font-size: 16px
    span.num
      display: inline-block
      position: relative
      padding: 6px 10px
      background: rgba(111, 155, 243, 0.15)
      border-radius: 8px
      font-style: normal
      font-weight: 600
      font-size: 18px
      line-height: 100%
      color: #6F9BF3
      margin-left: 5px
      +r(767)
        font-size: 15px
    .btn-light
      display: inline-flex
      align-items: center
      height: 30px
      line-height: 30px
      padding: 0 10px
      font-style: normal
      font-weight: 500
      font-size: 14px
      letter-spacing: 0.02em
      color: #6F9BF3
      margin-left: 15px
      +ico()
        margin-left: 5px
  &__action
    .data
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      text-transform: uppercase
      color: #5B6485
    .btn-icon
      padding: 0
    .btn-light
      display: inline-flex
      align-items: center
      height: 30px
      line-height: 30px
      padding: 0 10px
      font-style: normal
      font-weight: 500
      font-size: 14px
      letter-spacing: 0.02em
      color: #6F9BF3
      +ico()
        margin-left: 5px
  &__body

    .footable
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      color: #5B6485
      +fz(14, 11)
      th, td
        border: none
      thead
        background: #F7F8FB
        th
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #A3AED0
          padding: 12px 10px
      tbody
        border: none
        td
          padding: 17px 0 17px 10px

  &__foot
    margin-top: 15px
    display: flex
    justify-content: flex-end
    align-items: center
    padding-top: 15px
    +r(767)
      flex-direction: column
      justify-content: flex-start
      align-items: stretch
      margin-top: 10px
      padding-top: 10px
      justify-content: center
    & > * + *
      margin-left: 20px
      +r(767)
        margin-left: 0
        margin-top: 10px

.total-box
  padding: 14px 23px
  border: 2px solid #628EFF
  box-sizing: border-box
  border-radius: 8px

.total
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  text-align: right
  letter-spacing: 0.02em
  color: #A3AED0

  +r(767)
    display: flex
    justify-content: space-between

  strong
    display: inline-block
    font-style: normal
    font-weight: bold
    font-size: 20px
    line-height: 100%
    text-align: right
    letter-spacing: 0.02em
    color: #5B6485
    margin-left: 10px













