@font-face
  font-family: 'footable'
  src: url('../fonts/footable.eot')
  src: url('../fonts/footable.eot') format("embedded-opentype"), url('../fonts/footable.woff2') format("woff2"), url('../fonts/footable.woff') format("woff"), url('../fonts/footable.ttf') format("truetype"), url('../fonts/footable.svg') format("svg")
  font-weight: normal
  font-style: normal

.fooicon
 display: inline-block
 font-size: inherit
 font-family: footable!important
 font-style: normal
 font-weight: 400
 line-height: 1
 text-rendering: auto
 -webkit-font-smoothing: antialiased
 -moz-osx-font-smoothing: grayscale
 transform: translate(0)

.fooicon:after,
.fooicon:before
 box-sizing: border-box

.fooicon-loader:before
  content: '\e800'
.fooicon-plus:before
  content: '\e801'
.fooicon-minus:before
  content: '\e802'
.fooicon-search:before
  content: '\e803'
.fooicon-remove:before
  content: '\e804'
.fooicon-pencil:before
  content: '\e805'
.fooicon-flash:before
  content: '\e806'
.fooicon-eye-close:before
  content: '\e807'
.fooicon-cog:before
  content: '\e808'
.fooicon-stats:before
  content: '\e809'
.fooicon-sort:before
  content: '\f0dc'
.fooicon-sort-asc:before
  content: '\f160'
.fooicon-sort-desc:before
  content: '\f161'
.fooicon-trash:before
  content: '\f1f8'

.js-table
  opacity: 0
  &.footable
    opacity: 1

#table-nav
  opacity: 0
  width: 140px
  height: 35px
  +r(767)
    width: 140px
  &.footable-paging-external
    opacity: 1
    transition-delay: 500ms

* > .table-caption
  margin-top: 30px
  +r(767)
    margin-top: 15px

.table-caption
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 20px
  background: #fff
  +r(767)
    padding: 10px
    border-radius: 8px 8px 0 0
  +r(575)
    flex-direction: column
    align-items: stretch
  h1, h2, h3, h4, h5, h6
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485
    margin: 0
    +fz(18, 13)
  .btn-secondary
    padding: 0 20px
    height: 35px
    background: #A3AED0
    border-color: #A3AED0
    box-shadow: 0px 8px 10px rgba(204, 210, 227, 0.5)
    border-radius: 8px
  & > div:last-child
    +r(575)
      flex-direction: column
      justify-content: stretch
      align-items: stretch
  .btn + .btn
    margin-left: 20px
    +r(767)
      margin-left: 10px
    +r(575)
      margin-left: 0
      margin-top: 10px

.table-filter
  margin-bottom: 30px
  +r(767)
    margin-bottom: 16px

.table-head
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 20px
  +r(767)
    flex-direction: column
    justify-content: center
    align-items: center
    margin-bottom: 10px
  +r(575)
    align-items: stretch
  & > * + *
    +r(767)
      margin-top: 10px
  & > div:last-child
    display: flex
    justify-content: flex-end
    align-items: center
    +r(575)
      flex-direction: column
      justify-content: stretch
      align-self: stretch
    & > *
      +r(575)
        width: 100%
      & + *
        margin-left: 20px
      +r(575)
        margin-top: 10px
        margin-left: 0
    .btn
      +r(575)
        width: 100%
      &.btn-icon
        +r(575)
          width: 45px
          align-self: flex-end

    .btn
      & + .btn
        margin-left: 20px
        +r(575)
          margin-left: 0

    .btn-icon
      width: 45px
      height: 45px
      flex: 0 0 45px
      font-size: 20px
      color: #CCD2E3

      &:hover,
      &:focus
        color: #A3AED0
        box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
      &:active
        color: #FF6A6A
        box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)

.footable
  thead
    .footable-header
      background: #F7F8FB
      th
        padding: 14px 10px 12px 10px
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #E4E7EF
        +r(1199)
          font-size: 11px
          padding: 10px 5px
        +r(767)
          font-size: 10px
        .btn-icon.ico_plus
          display: flex
          justify-content: center
          align-items: center
          width: 35px
          height: 35px
          flex: 0 0 35px
          background: #707997
          color: #fff
          font-size: 12px
          cursor: pointer
          +h
            box-shadow: none
  tbody
    tr
      border-bottom: 1px solid #F7F8FB
      td
        padding: 12px 10px 12px 10px
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #5B6485
        +r(1199)
          padding: 10px 5px
          font-size: 12px
        +r(767)
          font-size: 11px
          height: 45px
        a
          display: block
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #2283FF
          white-space: nowrap
          +tr(0.3s)
          +r(1199)
            font-size: 12px
          +r(767)
            font-size: 11px
          +h
            color: #5B6485

        .accent
          display: block
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #A3AED0
          white-space: nowrap
          +r(1199)
            font-size: 12px
          +r(767)
            font-size: 11px

        .dropdown
          .dropdown-toggle
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 100%
            letter-spacing: 0.02em
            color: #A3AED0
            &:after
              display: none
            +h
              color: $accent
            +r(1199)
              font-size: 12px
          .dropdown-menu.show
            display: block
          .dropdown-menu
            border: none
            background: #FFFFFF
            box-shadow: 0px 5px 15px -1px rgba(163, 174, 208, 0.3), 0px 0px 20px -1px rgba(163, 174, 208, 0.3)
            border-radius: 0px 8px 8px 8px
            +r(1199)
              padding: 10px
              min-width: auto
          .dropdown-item
            font-style: normal
            font-weight: 500
            font-size: 14px
            line-height: 16px
            letter-spacing: 0.02em
            color: #5B6485
            cursor: pointer
            +r(1199)
              font-size: 12px
              padding: 5px 10px

        .btn-outline-primary
          display: inline-flex
          align-items: center
          height: 35px
          padding: 6px
          background: #FFFFFF
          border: 2px solid rgba(34, 131, 255, 0.3)
          box-sizing: border-box
          border-radius: 8px
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: $accent
          +tr(0.3s)
          +ico()
            font-size: 15px
            margin-right: 5px
            color: $accent
            +tr(0.3s)
          +h
            background-color: $accent
            color: #fff
            +tr(0.3s)
            +ico()
              color: #fff
              +tr(0.3s)
          +r(1199)
            font-size: 12px
          +r(767)
            height: 30px
            font-size: 11px

        .form-control
          border: 1px solid #E4E7EF
          box-shadow: none
          &:focus
            border: 1px solid #A3AED0

.table-body
  .footable
    background: #fff
    thead.hidden
      display: none
    thead
      .footable-header
        background: #5B6485

        th:first-child
          padding-left: 30px
          +r(1199)
            padding-left: 10px

        th:last-child
          padding-right: 20px
          +r(1199)
            padding-right: 10px

        th.hidden
          opacity: 0

        .w-1
          width: 1px

    tbody
      border-top: 10px solid #fff
      border-bottom: 10px solid #fff
      +r(767)
        border: none
      tr
        +h
          background-color: #F7F8FB

        td
          vertical-align: middle

        td:first-child
          padding-left: 30px
          position: relative
          +r(1199)
            padding-left: 10px
          &::before
            content: ''
            display: block
            position: absolute
            top: 1px
            left: 0
            width: 10px
            height: 100%
            background-color: #fff
            +r(1199)
              display: none
          +r(767)
            padding-left: 15px

        td:last-child
          padding-right: 30px
          position: relative
          +r(1199)
            padding-right: 10px
          &::after
            content: ''
            display: block
            position: absolute
            top: 1px
            right: 0
            width: 10px
            height: 100%
            background-color: #fff

        .footable-first-visible
          +r(767)
            padding-left: 15px

        .btn-icon
          padding: 0
          display: inline-flex
          width: 35px
          height: 35px
          flex: 0 0 35px
          box-sizing: border-box
          background: #FFFFFF
          box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
          border-radius: 8px
          color: #CCD2E3
          font-size: 20px
          +r(767)
            width: 30px
            height: 30px
            flex: 0 0 30px
          +ico()
            margin: 0
            padding: 0
            font-size: 12px
          &:hover,
          &:focus
            color: #A3AED0
            box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
          &:active
            color: #FF6A6A
            box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)

          &.ico_plus
            font-size: 12px

        .red
          color: #FF6A6A

        .btns
          display: inline-flex
          align-items: center
          +r(767)
            display: flex
            flex-direction: column
            align-items: stretch

          .btn
            +r(767)
              width: 100%

          div
            & + div
              margin-left: 20px
              +r(1359)
                margin-left: 10px
              +r(767)
                margin-left: 0
                margin-top: 10px

        .btn
          +r(767)
            padding: 4px 12px
          & + .btn
            margin-left: 20px
            +r(767)
              margin-left: 10px

        .btn-secondary
          background: #A3AED0
          box-shadow: 0px 8px 10px -10px rgba(204, 210, 227, 0.5)
          border-radius: 8px
          font-style: normal
          font-weight: normal
          font-size: 14px
          text-align: center
          letter-spacing: 0.02em
          color: #FFFFFF

        .btn-primary
          background: $accent
          border-color: $accent
          box-shadow: 0px 8px 10px -10px rgba(204, 210, 227, 0.5)
          border-radius: 8px
          font-style: normal
          font-weight: normal
          font-size: 14px
          text-align: center
          letter-spacing: 0.02em
          color: #FFFFFF
          padding: 6px 20px
          +hh
            background-color: darken($accent, 10%)

        .w-1
          width: 1px

    tfoot
      tr
        td
          padding: 20px 10px
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #5B6485


.table-footer
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 30px

  +r(767)
    margin-top: 16px
    flex-direction: column
    align-items: stretch

  & > div:first-child
    +r(767)
      margin-bottom: 10px
    .drop
      width: 350px
      +r(1199)
        width: 260px
      +r(767)
        margin: 0 auto
        width: auto

.footable
  .dropdown-menu
    padding: 10px
  .dropdown-item
    padding: 5px 10px
    +hh
      background: #E4E7EF

table.footable-details
  opacity: 1
  &>tbody
    border: none!important
    &>tr
      &:last-child
        border-bottom: 0
      &>th:first-child
        width: 50%
        max-width: 300px

        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 100%
        letter-spacing: 0.02em
        color: #A3AED0

        &::before
          display: none!important

      &>td:nth-child(2)
        width: 50%
        height: 45px
        text-align: right!important
        padding: 5px 0 5px 5px!important
        &:after
          display: none!important

table.footable
  .footable-detail-row
    border: 2px solid $accent
    & > td
      padding-top: 10px!important
      padding-left: 10px!important
      padding-right: 10px!important
      &::before
        display: none!important
      +r(767)
        padding: 10px 15px!important
    & > td:first-child(),
    & > td:last-child()
      border: none!important
  &.breakpoint

    td
      height: 45px

      &::before,
      &::after
        display: none!important

    .footable-last-visible
      position: relative
      padding-right: 50px

      .footable-toggle
        display: flex
        justify-content: center
        align-items: center
        width: 35px
        height: 35px
        margin: -17px 0 0 0!important
        background: #FFFFFF
        box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.2)
        border-radius: 8px
        color: #707997
        opacity: 1!important
        float: none
        position: absolute
        top: 50%
        right: 10px
        cursor: pointer
        +h
          color: $accent

      .fooicon-plus
        +icon(arrow-down)
        font-size: 17px
      .fooicon-minus
        +icon(arrow-up)
        color: $accent

  .receivable-progress-grid
    display: flex
    justify-content: flex-end
    +r(767)
      flex-direction: column
      align-items: flex-end

  .receivable-progress
    text-align: left
    +r(767)
      text-align: right
    & + .receivable-progress
      margin-top: 0
      margin-left: 20px
      +r(767)
        margin-left: 0
        margin-top: 10px
    &__value
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
    &__line
      min-width: 130px
      height: 6px
      background: #EBF2FF
    &__line-value
      height: 6px
    &__dates
      margin-top: 4px
      font-style: normal
      font-weight: 500
      font-size: 11px
      line-height: 100%
      letter-spacing: 0.02em
      color: #A3AED0


.select-status
  display: block
  position: relative

  +h
    .select-status__drop
      opacity: 1
      visibility: visible
      pointer-events: all

  &__btn
    margin-left: 10px
    button
      display: flex
      justify-content: center
      align-items: center
      width: 14px
      height: 14px
      font-size: 14px
      border: none
      background: none
      color: #CCD2E3
      cursor: pointer
  &__drop
    display: block
    position: absolute
    top: -50px
    left: -150px
    background: #FFFFFF
    box-shadow: 0px 5px 15px 0px rgba(163, 174, 208, 0.3), 0px 0px 20px 0px rgba(163, 174, 208, 0.3)
    border-radius: 8px
    padding: 10px
    width: 205px
    z-index: 2

    +tr(0.3s)

    opacity: 0
    visibility: hidden
    pointer-events: none

    ul
      list-style: none
      padding: 0
      margin: 0

  &__item
    display: flex
    justify-content: flex-start
    align-items: center
    width: 100%
    height: 30px
    padding: 0 10px
    cursor: pointer
    border-top: 1px solid #fff
    border-bottom: 1px solid #fff

    &.is-active
      background: #F7F8FB

    span.circle
      display: block
      position: relative
      width: 14px
      height: 14px
      flex: 0 0 14px
      margin-right: 5px
      &::before
        content: ''
        display: block
        width: 14px
        height: 14px
        background: currentColor
        opacity: 0.3
        border-radius: 50px
      &::after
        content: ''
        display: block
        width: 4px
        height: 4px
        border-radius: 50%
        background-color: currentColor
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    span:last-child
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      color: #5B6485

    +h
      background: #F7F8FB

table.footable-details.table-inner
  tbody
    tr
      th
        display: none!important
        width: 0!important
        max-width: 0!important
      td:nth-child(2)
        width: 100%
        text-align: left!important


      td
        span[data-label]
          display: flex
          justify-content: space-between
          align-items: center
          &::before
            content: attr(data-label)
            width: 50%
            font-style: normal
            font-weight: 500
            font-size: 11px
            line-height: 110%
            letter-spacing: 0.02em
            color: #A3AED0
            margin-right: 10px

table.footable.table-inner .footable-detail-row > td
  +r(767)
    padding-bottom: 0!important

table.footable-details.table-inner > tbody > tr > td:nth-child(2)
  +r(767)
    padding-bottom: 0!important

.table-inner-box
  padding-top: 10px
  padding-left: 130px
  +r(1399)
    padding-left: 0
  +r(1199)
    background: rgba(163, 174, 208, 0.2)
    padding: 10px
  +r(767)
    margin-left: -20px
    margin-right: -16px

.table-inner
  list-style-type: none
  padding: 0
  margin: 0
  &__row
    display: grid
    grid-template-columns: 2fr 1fr 1fr 3fr
    align-items: center
    grid-row: 1
    text-align: left
    grid-column-gap: 15px

    +r(1199)
      grid-template-columns: 150px 90px 80px 1fr
      grid-column-gap: 10px

    +r(767)
      display: flex
      flex-wrap: wrap

    & + .table-inner__row
      margin-top: 5px
      padding-top: 5px
      border-top: 1px solid #F7F8FB
      +r(767)
        margin-top: 15px
        padding-top: 15px
        border-top: 5px solid #F7F8FB
  &__cell
    & + .table-inner__cell
      +r(767)
        margin-top: 10px

    &:nth-child(2),
    &:nth-child(3)
      +r(1199)
        text-align: center
      +r(767)
        text-align: left


    &:nth-child(4)
      .table-inner__grid
        justify-content: flex-end
        +r(767)
          flex-direction: column
          align-items: flex-start
          justify-content: flex-start
          .btn-status
            margin-bottom: 10px

  &__grid
    display: flex
    align-items: center
    position: relative

    .btn-status + span
      font-size: 11px

    .product-img-small
      position: static
      width: 50px
      height: 50px
      flex: 0 0 50px
      z-index: 1
      +r(1199)
        width: 40px
        height: 40px
        flex: 0 0 40px
      .popap-img
        display: block
        position: absolute
        top: 50%
        left: 60px
        margin-top: -150px
        width: 300px
        height: 300px
        background-color: #fff
        box-shadow: 0px 0px 20px 0 rgba(163, 174, 208, 0.3), 0px 5px 15px 0 rgba(163, 174, 208, 0.3)
        border-radius: 8PX
        padding: 10px
        z-index: 2
        transform: scale(0)
        +tr(0.3s)
        +r(1199)
          width: 200px
          height: 200px
          margin-top: -100px
        img
          width: 280px
          height: 280px
          +r(1199)
            width: 180px
            height: 180px
      +h
         .popap-img
           transform: scale(1)


    .btn-status
      margin-right: 15px
      & + *
        flex-grow: 1

    .btn + .btn
      +r(1199)
        margin-left: 0!important
        margin-top: 10px

.table-message
  display: flex
  justify-content: flex-start
  margin-bottom: 30px
  +r(767)
    justify-content: center

.btn-message
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: left
  min-height: 45px
  background: #FFFFFF
  border: 2px solid rgba(255, 106, 106, 0.3)
  box-sizing: border-box
  box-shadow: 0px 5px 15px 0 rgba(255, 106, 106, 0.3)
  border-radius: 0px 8px 8px 8px
  padding: 0 10px

  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #FF6A6A

  +ico()
    font-size: 20px
    margin-right: 5px

  br
    display: none
    +r(767)
      display: block
