


.widjet-overdue-list
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    & + .widjet-overdue-list__item
      margin-top: 20px

    &:nth-child(3n + 1)
      .widjet-overdue-list__info:before
        background: #73CD9B

    &:nth-child(3n + 2)
      .widjet-overdue-list__info:before
        background: #796BC2

    &:nth-child(3n + 3)
      .widjet-overdue-list__info:before
        background: #628EFF
  &__info
    display: block
    position: relative
    padding-left: 24px
    +r(767)
      padding-left: 15px
    &::before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 4px
      height: 100%
      box-shadow: 1px 0px 4px 1px rgba(115, 205, 155, 0.3)
  &__company
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
    +r(767)
      font-size: 12px
  &__phone
    display: block
    position: relative
    margin: 0 5px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
    margin-top: 4px
    +r(767)
      font-size: 12px
  &__price-info
    display: flex
    flex-direction: column
    align-items: flex-end
    margin-left: auto
    margin-right: 20px
  &__price
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    text-align: right
    letter-spacing: 0.02em
    color: #5B6485
    white-space: nowrap
    +r(767)
      font-size: 12px
  &__days
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    text-align: right
    letter-spacing: 0.02em
    color: #FF6A6A
    margin-top: 4px
    +r(767)
      font-size: 12px