.widjet.--services

  .widjet__body
    .location-select
      margin-bottom: 20px
      .drop
        padding-left: 50px
        position: relative
        &::before
          content: ''
          display: block
          position: absolute
          top: 0
          left: 0
          width: 40px
          height: 45px
          background-image: url(../img/location.svg)
          background-repeat: no-repeat
          background-position: center
          background-size: 14px
        .drop-box
          left: 50px
          width: calc(100% - 50px)

.widjets-service-list
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    & + .widjets-service-list__item
      margin-top: 12px
    &:last-child
      .widjets-service-list__dot
        &::after
          display: none
    &:nth-child(1)
      .widjets-service-list__dot
        &:before
          background: #57CA70
        &:after
          background: linear-gradient(180deg, #57CA70 0%, #4991F8 100%)
    &:nth-child(2)
      .widjets-service-list__dot
        &:before
          background: #4991F8
        &:after
          background: linear-gradient(180deg, #4991F8 0%, #BDCFF4 100%)
    &:nth-child(3)
      .widjets-service-list__dot
        &:before
          background: #BDCFF4

  &__dot
    display: flex
    justify-content: center
    align-items: center
    position: relative
    width: 40px
    height: 40px
    background: #F7F8FB
    border-radius: 50%
    margin-right: 15px
    span
      width: 6px
      height: 6px
      border-radius: 50%
      background-color: #fff
      position: relative
      z-index: 3
    &::before
      content: ''
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 20px
      height: 20px
      border-radius: 50%
      z-index: 2
    &:after
      content: ''
      display: block
      position: absolute
      top: calc(50% - 10px)
      left: calc(50% - 1px)
      width: 2px
      height: calc(100% + 20px)
      background: #57CA70
      z-index: 1
  &__title
    flex-grow: 1
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
  &__date
    display: block
    margin-left: auto
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    text-align: right
    letter-spacing: 0.04em
    color: #A3AED0

.widjet.--services-2
  background-image: url(../img/bg_widget-services-2.svg)
  background-repeat: no-repeat
  background-position: right bottom 20px

  .widjets-service-list__item + .widjets-service-list__item
    margin-top: 20px

  .widjets-service-list__item
    justify-content: flex-start
    .widjets-service-list__date
      text-align: left
      margin-top: 5px