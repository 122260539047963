.canvas
  display: block
  position: relative
  margin-top: 60px
  margin-left: 80px
  height: calc(100% - 60px)
  box-sizing: border-box
  +r(1199)
    margin: 0
    height: auto

.canvas-top-panel
  display: inline-block
  position: absolute
  top: -60px
  left: -80px
  padding: 20px 80px
  background: #E4E7EF
  border-radius: 30px 30px 0 0
  +r(1199)
    position: relative
    top: auto
    left: auto
    display: block
    padding: 30px 30px 20px 30px
    border-bottom: 1px solid #CCD2E3
  +r(767)
    padding: 15px 15px 0 15px
    border-radius: 15px 15px 0 0
  ul
    display: flex
    justify-content: flex-start
    align-items: center
    list-style-type: none
    flex-wrap: wrap
    padding: 0
    margin: 0
    li
      margin: 0 30px 15px 0
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
      +fz(14, 11)
      &:last-child
        margin-right: 0

.canvas-left-panel
  position: absolute
  left: -80px
  top: -1px
  background: #E4E7EF
  padding: 20px 0 80px
  border-radius: 0 0 0 30px
  +r(1199)
    position: relative
    top: auto
    left: auto
    border-radius: 0
    padding: 0 30px
  +r(767)
    padding: 0 15px

.canvas-menu
  display: inline-flex
  flex-direction: column
  +r(1199)
    flex-direction: row

  button
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    border: none
    background: none
    width: 80px
    height: 80px
    font-size: 32px
    color: rgba(163, 174, 208, 0.7)
    +r(1199)
      width: 75px
      height: 75px
    +r(767)
      width: 65px
      height: 65px
    &.is-active
      color: $accent

.canvas-box
  display: flex
  justify-content: center
  align-items: center
  background: #FFFFFF
  border-radius: 8px
  min-height: 700px
  height: 100%
  position: relative
  box-sizing: border-box
  padding: 20px
  +r(1199)
    min-height: 750px
  +r(767)
    min-height: 400px
    align-items: flex-end

  img
    max-width: 95%
    max-height: 95%

.canvas-tool
  display: inline-block
  position: absolute
  top: 15px
  left: 15px
  background: #FFFFFF
  border: 2px solid #F7F8FB
  box-sizing: border-box
  padding: 20px 20px 40px
  +r(1199)
    top: 25px
    left: 25px
    padding: 10px 50px 10px 16px
    border: 1px solid #F7F8FB
  +r(767)
    top: 10px
    left: 10px
    right: 10px
    padding: 10px
  ul
    display: flex
    flex-direction: column
    list-style-type: none
    padding: 0
    margin: 0
    +r(1199)
      flex-direction: row
      flex-wrap: wrap
    li
      +r(1199)
        display: flex
        align-items: center
      +r(767)
          margin: 0 13px 15px 0

      & + li
        margin-top: 12px
        text-align: center
        +r(1199)
          margin-top: 0
          margin-left: 10px
        +r(767)
          margin-left: 0
      &:nth-child(6)
        a
          font-size: 14px
      a
        font-size: 22px
        border: none
        background: none
        color: #CCD2E3
        &::before
          width: auto
          max-width: 33px
        &.is-active
          color: $accent
  .btn-icon
    position: absolute
    bottom: -20px
    left: 50%
    transform: translateX(-50%)
    +r(1199)
      bottom: auto
      left: 100%
      top: 50%
      margin-top: -17.5px
    +r(767)
      top: auto
      bottom: -20px
      left: 50%
      transform: translateX(-50%)
      margin-top: 0



.widjet
  &.--add-detail

    .add-detail-box
      display: block
      position: relative
      &::after
        content: ''
        display: block
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        height: 25px
        background: rgb(255,255,255)
        background: -moz-linear-gradient(0deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.8743872549019608) 70%, rgba(255,255,255,0) 100%)
        background: -webkit-linear-gradient(0deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.8743872549019608) 70%, rgba(255,255,255,0) 100%)
        background: linear-gradient(0deg, rgba(255,255,255,1) 35%, rgba(255,255,255,0.8743872549019608) 70%, rgba(255,255,255,0) 100%)
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1)
        z-index: 4
        +r(1199)
          display: none

    .add-detail-overflow
      position: relative
      overflow: auto
      height: 485px
      width: calc(100% + 30px)
      padding-right: 30px
      padding-bottom: 30px

      scrollbar-color: #CCD2E3 #E4E7EF
      scrollbar-width: thin!important
      -moz-appearance: none !important
      &::-webkit-scrollbar
        width: 4px
      &::-webkit-scrollbar-track
        background-color: #E4E7EF
      &::-webkit-scrollbar-thumb
        background-color: #CCD2E3

      +r(1199)
        height: auto
        overflow: visible

.widjet
  &.--list-operations
    background-image: url(../img/bg_widjet-orders.svg)
    background-repeat: no-repeat
    background-position: left bottom
    +r(1399)
      background-image: none
    .widjet__title
      +r(1399)
        margin-bottom: 20px

.checkboxs
  display: flex
  align-items: center
  & > div + div
    margin-left: 10px


.scheme-box
  display: flex
  justify-content: center
  align-items: center
  position: relative
  border: 1px solid #F7F8FB
  box-sizing: border-box
  width: 200px
  height: 120px
  margin: 0 auto 20px
  padding: 30px
  &__img
    display: flex
    justify-content: center
    align-items: center
    height: 60px
    img
      width: 100%
      height: 100%
  &__checkbox
    position: absolute
    width: 28px
    height: 28px

    &.--top
      top: 0
      left: 50%
      margin-left: -14px

    &.--left
      top: 50%
      right: 5px
      margin-top: -14px

    &.--bottom
      bottom: 0
      left: 50%
      margin-left: -14px

    &.--right
      top: 50%
      left: 5px
      margin-top: -14px

    &.--center
      display: none

  &.tpl-5
    .scheme-box__checkbox.--center
      display: block
      bottom: 30px
      left: 51px
  &.tpl-6
    .scheme-box__checkbox.--center
      display: block
      bottom: 26px
      left: 84px
  &.tpl-7
    .scheme-box__checkbox.--center
      display: block
      bottom: 47px
      left: 66px
  &.tpl-8
    .scheme-box__checkbox.--center
      display: block
      bottom: 60px
      left: 55px
  &.tpl-10
    .scheme-box__checkbox.--center
      display: block


label.checkbox-small
  display: inline-block
  position: relative
  padding: 10px
  cursor: pointer
  input[type="checkbox"],
  input[type="radio"]
    display: none
  span
    display: block
    width: 8px
    height: 8px
    background: #CCD2E3

  input[type="checkbox"],
  input[type="radio"]
    &:checked
      & + span
        background: $accent