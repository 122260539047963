
// Новий
.new
  color: #628EFF

// Підтверджено
.confirmed
  color: #57CA70

// Скасовано
.canceled
  color: #FF6A6A

// Чернетка
.cornet
  color: #796BC2

// На розгляді
.consideration
  color: #FBA705

// Видалено
.removed
  color: #A3AED0

.btn-status
  position: relative
  display: inline-flex
  align-items: center
  box-sizing: border-box
  white-space: nowrap
  height: 35px
  padding: 10px 11px
  +r(767)
    padding: 8px
    height: 30px

  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    border-radius: 8px
    border: 2px solid currentColor
    opacity: 0.3

  span.circle
    display: block
    position: relative
    width: 14px
    height: 14px
    flex: 0 0 14px
    margin-right: 5px
    &::before
      content: ''
      display: block
      width: 14px
      height: 14px
      background: currentColor
      opacity: 0.3
      border-radius: 50px
    &::after
      content: ''
      display: block
      width: 4px
      height: 4px
      border-radius: 50%
      background-color: currentColor
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

body.no-scroll
  overflow: hidden

.page-wrapper
  min-width: 320px
  min-height: 100vh
  max-width: 1920px
  margin: 0 auto
  display: flex
  flex-direction: column
  justify-content: space-between
  background-color: #F7F8FB
  overflow: hidden
  .page-main
    flex-grow: 1

.page-head
  display: block
  position: relative
  background: url(../img/bg_page-head.jpg)
  background-position: center
  background-size: cover
  padding-top: 35px
  padding-bottom: 35px
  +r(767)
    background-position: left 35% center
  &__inner
    display: flex
    justify-content: space-between
    align-items: center
    +r(767)
      flex-direction: column-reverse
  &__title
    text-align: center
    font-style: normal
    font-weight: 600
    font-size: 28px
    line-height: 110%
    letter-spacing: 0.04em
    color: #fff
    +fz(28, 20)
  &__link
    margin-right: 15px
    +r(767)
      margin-right: 0
      margin-top: 15px
    a
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.04em
      color: #FFFFFF
  &__empty
    margin-left: 15px
    min-width: 100px
    +r(767)
      display: none

.page-content
  padding-top: 50px
  padding-bottom: 50px

  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 140%
  color: #5B6485

  +r(767)
    padding-top: 30px
    padding-bottom: 30px

  h4
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485

  h5
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 140%
    color: #5B6485

  .row
    & + .row
      margin-top: 30px
      +r(1199)
        margin-top: 15px

.nowrap
  white-space: nowrap

.list-square
  list-style-type: none
  padding-left: 0
  li
    display: block
    position: relative
    padding-left: 17px
    &::before
      content: ''
      display: block
      position: absolute
      left: 0
      top: 0.4em
      width: 7px
      height: 7px
      flex: 0 0 7px
      background: #BDCFF4

.scroll-box
  scrollbar-color: $accent #E2EBEC
  scrollbar-width: thin!important
  -moz-appearance: none !important

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: #E2EBEC

  &::-webkit-scrollbar-thumb
    background-color: $accent

.datepickers-container
  z-index: 1100

.datepicker
  width: 265px
  border: 1px solid #A3AED0
  font-family: $font-base

.datepicker--pointer
  border-top: 1px solid #A3AED0
  border-right: 1px solid #A3AED0

.datepicker--nav
  border-bottom: 1px solid #A3AED0

.datepicker--days-names
  background: #F7F8FB

.datepicker--day-name
  font-style: normal
  font-weight: 600
  font-size: 12px
  line-height: 14px
  text-align: center
  letter-spacing: 0.02em
  color: #A3AED0
  height: 24px
  text-transform: none

.datepicker--cell.-selected-
  background: none
  position: relative
  color: #fff
  font-size: 12px
  &::before
    content: ''
    display: block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 24px
    height: 24px
    border-radius: 50%
    background-color: $accent
    z-index: -1

.datepicker
  .datepicker--cell.-current-
    color: $accent
    background: none

.search
  display: block
  position: relative
  &-group
    display: block
    position: relative
  &-control
    width: 100%
    height: 45px
    display: block
    position: relative
    background: #FFFFFF
    border: 1.5px solid #E4E7EF
    box-sizing: border-box
    border-radius: 8px
    padding: 0 45px 0 15px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: $base
    +placeholder()
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      color: #A3AED0
  &-btn
    width: 45px
    height: 45px
    position: absolute
    top: 0
    right: 0
    display: flex
    justify-content: center
    align-items: center
    border: none
    background: none
    color: #E1E5EE

.btn-icon
  display: inline-flex
  justify-content: center
  align-items: center
  width: 35px
  height: 35px
  flex: 0 0 35px
  padding: 0
  border: none
  background: #FFFFFF
  box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.2)
  border-radius: 8px
  color: #CCD2E3
  font-size: 12px
  +tr(0.3s)
  &.ico_trash
    font-size: 20px
  &.btn-primary
    background: $accent
    color: #fff
  &:hover,
  &:focus
    color: #A3AED0
    box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
  &:active
    color: #FF6A6A
    box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)

.switcher
  display: inline-flex
  align-items: center
  border-radius: 8px
  background: #E4E7EF
  label
    display: block
    position: relative
    cursor: pointer
    input[type="radio"]
      position: absolute
      display: none
      opacity: 0
    span
      display: inline-block
      position: relative
      padding: 15px 20px
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      text-align: center
      letter-spacing: 0.02em
      color: #5B6485
      border-radius: 8px
    input[type="radio"]:checked + span
      background-color: $accent
      color: #fff

.input-date
  padding-right: 40px
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='16' fill='none' viewBox='0 0 15 16'%3E%3Cpath fill='%23A3AED0' fill-rule='evenodd' d='M0 .09h3v3H0v-3zm0 6h3v3H0v-3zm3 6H0v3h3v-3zm3-12h3v3H6v-3zm3 6H6v3h3v-3zm-3 6h3v3H6v-3zm9-12h-3v3h3v-3zm-3 6h3v3h-3v-3zm3 6h-3v3h3v-3z' clip-rule='evenodd'/%3E%3C/svg%3E%0A")
  background-repeat: no-repeat
  background-position: right 15px center

.checkbox-label
  display: flex
  align-items: center
  cursor: pointer

.checkbox
  display: block
  position: relative
  padding-left: 25px
  &::before
    content: ''
    display: block
    position: relative
    top: -1px
    left: -1px
    width: 25px
    height: 25px
    border: 1.5px solid #E4E7EF
    background: #fff
    box-sizing: border-box
    border-radius: 4px
    cursor: pointer
  &:after
    content: ''
    display: block
    position: absolute
    top: 8px
    left: 8px
    width: 8px
    height: 8px
    background-color: #5B6485
    opacity: 0
    pointer-events: none
    +tr(0.3s)

  input[type="checkbox"]
    display: none
    &:checked
      & ~ &:after
        opacity: 1

.js-show-input
  & + .form-group
    margin-top: 10px
    display: none

.filter
  margin-bottom: 30px

.btn-filter
  display: flex
  align-items: center
  height: 45px
  +ico()
    margin-right: 5px
    transform: rotate(0deg)
  .txt-1
    display: block
  .txt-2
    display: none
  &:not(.collapsed)
    +ico()
      transform: rotate(180deg)
    .txt-1
      display: none
    .txt-2
      display: block

.tabs-nav
  display: flex
  justify-content: space-between
  border-bottom: 1px solid #E4E7EF
  margin-bottom: 50px
  +r(767)
    margin-bottom: 25px
  & > *
    flex-grow: 1
    max-width: 50%
    text-align: center
    & > *
      border: none
      width: 100%
      text-align: center
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
      border-bottom: 4px solid transparent
      +tr(0.3s)
      +fz(18, 14)
      &.active
        border-bottom: 4px solid $accent
        color: #5B6485
      +h
        color: #5B6485

.nice-select
  display: inline-flex
  align-items: center
  background: #FFFFFF
  border: 1px solid #E4E7EF
  box-sizing: border-box
  border-radius: 8px
  &.open
    border-radius: 8px 8px 0 0
    border: 1px solid #A3AED0
  &:after
    right: 13px
    border-color: #A3AED0
  .list
    border: 1px solid #A3AED0
    border-radius: 0 0 8px 8px
    box-sizing: border-box
    border-top: none
    margin: 2px 0 0 0
    padding: 0
    .option
      height: 30px
      min-height: 30px
      line-height: 30px
      font-style: normal
      font-weight: 500
      font-size: 14px
      letter-spacing: 0.02em
      color: #A3AED0
      padding-left: 15px
      &.selected
        font-style: normal
        font-weight: 500
        font-size: 14px
        letter-spacing: 0.02em
        color: #5B6485
      & + .option
        border-top: 1px solid #F7F8FB


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
    -moz-appearance: textfield

input[type="number"]
    -moz-appearance: textfield

input[type="number"]:hover,
input[type="number"]:focus
    -moz-appearance: number-input

.delivery-icon
  +r(767)
    width: 60px
    margin-right: 10px

.form-control-empty
  height: 25px
  +r(767)
    height: 0

.double-inputs
  display: flex
  justify-content: space-between
  align-items: center
  & > *
    flex-grow: 1
    width: 50%
    +hh
      border-color: #E4E7EF

  & > *:first-child
    border-radius: 8px 0 0 8px
    border-right: none

  & > *:last-child
    border-radius: 0 8px 8px 0

.widjet.--bonus span.ico_info
  cursor: pointer
  +h
    z-index: 10000
    color: $accent

.popover
  border: none

.bs-popover-end>.popover-arrow:before
  border: none

.popover.popover-bonus
  border: none
  top: -45px!important
  left: 115px!important
  max-width: 280px
  max-height: 200px

  .popover-arrow
    display: none!important

  .popover-body
    background: #FFFFFF
    box-shadow: 0px 5px 15px rgba(163, 174, 208, 0.3)
    border-radius: 0px 8px 8px 8px
    border: none!important
    padding-left: 50px

a[rel="popover"]
  display: block
  position: relative

.popover.popover-img
  max-width: none
  +r(767)
    max-width: 280px
    max-height: 180px
  .popover-body
    padding: 20px
    background: #FFFFFF
    box-shadow: 0px 0px 20px 0 rgba(163, 174, 208, 0.3), 0px 5px 15px -1px rgba(163, 174, 208, 0.3)
    border-radius: 8px 8px 8px 0px
    img
      width: 100%
    +r(767)
      padding: 10px

.input-file
  input[type="file"]
    display: none



.drop.--html
  max-width: 240px
  .drop-button
    padding-top: 2px
    padding-bottom: 0
    height: 45px

.print-content
  display: none

@media print
  .page-wrapper
    display: none

  .print-content
    display: block
    position: relative
    padding: 0 50px 50px 50px
    height: 100vh

    &__box
      display: flex
      flex-direction: column
      position: relative
      height: 100vh

    &__decor
      margin-bottom: 20px

    &__head
      display: flex
      flex-direction: column
      padding-bottom: 20px
      border-bottom: 1px solid rgba(#000, 0.5)

      span
        display: block
        position: relative
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #000000

      h1
        font-style: normal
        font-weight: bold
        font-size: 32px
        line-height: 1
        letter-spacing: 0.04em
        color: #000000
        margin: 10px 0

    &__body
      margin-bottom: 25px
      flex-grow: 1
      ul
        list-style-type: none
        padding: 0
        margin: 20px 0

        li
          display: flex
          justify-content: flex-start
          align-items: center

          & + li
            margin-top: 10px

          span:first-child
            display: block
            position: relative
            width: 200px
            font-style: normal
            font-weight: normal
            font-size: 12px
            line-height: 100%
            letter-spacing: 0.02em
            color: #000000
            margin-right: 30px

          span:last-child
            font-style: normal
            font-weight: 500
            font-size: 12px
            line-height: 100%
            letter-spacing: 0.02em
            color: #000000

    &__list-service

      span
        font-style: normal
        font-weight: normal
        font-size: 12px
        line-height: 100%
        letter-spacing: 0.02em
        color: #000000

      b
        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 100%
        letter-spacing: 0.02em
        color: #000000

      strong
        font-style: normal
        font-weight: 600
        font-size: 12px
        line-height: 100%
        letter-spacing: 0.02em
        color: #000000


      span:nth-child(1)
        width: 100px!important

      b:nth-child(2)
        width: 100px!important

      span:nth-child(3)
        width: 100px!important

    &__total
      display: block
      position: relative
      padding-left: 330px
      border-top: 1px solid rgba(#000, 0.5)
      padding-top: 25px

    &__to-pay
      span
        display: block
        position: relative
        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 100%
        color: #000000
        margin-bottom: 5px
      h2
        display: block
        position: relative
        font-style: normal
        font-weight: bold
        font-size: 22px
        line-height: 100%
        letter-spacing: 0.04em
        color: #000000

    &__paid
      margin-top: 10px
      span
        display: block
        position: relative
        font-style: normal
        font-weight: 500
        font-size: 12px
        line-height: 100%
        color: #000000
        margin-bottom: 5px
      h2
        display: block
        position: relative
        font-style: normal
        font-weight: 500
        font-size: 22px
        line-height: 100%
        letter-spacing: 0.04em
        color: #000000

    &__footer
      margin-top: 20px
      border-top: 1px solid #000
      padding-top: 15px
      display: flex
      justify-content: flex-start
      align-items: stretch
      margin-bottom: 50px

      & > *
        width: auto
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 0 30px

        &:first-child
          padding-left: 0

        & + *
          border-left: 1px solid rgba(#000, 0.4)

      &__phones,
      &__links
        span
          font-style: normal
          font-weight: 500
          font-size: 12px
          line-height: 100%
          letter-spacing: 0.04em
          color: #000000
          & + span
            margin-top: 10px

.details-list__table.--documents
  padding: 0
  margin-top: 30px
  .table-body
    margin-top: 0
    input.form-control.w-100
      max-width: 100%


.tagger
  display: block
  position: relative
  box-sizing: border-box
  min-height: 45px
  border: 1.5px solid #E4E7EF
  border-radius: 8px
  background: #FFFFFF
  color: #5B6485
  font-size: 14px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 1.14
  padding: 3px 10px 2px 10px

  & > ul
    margin: 0
    padding: 0
    flex-wrap: wrap
    justify-content: flex-start
    & > li
      margin: 0
      padding-left: 0
      padding-right: 5px
    & > li:not(.tagger-new)>a,
    & > li:not(.tagger-new)>span
      display: inline-flex
      align-items: center
      border-color: $accent
      background-color: $accent
      color: #fff
      box-sizing: border-box
      padding: 0 5px 0 10px
      margin: 2.5px 0

  .tagger-new
    margin: 0
    padding: 0 0 0 5px
    input
      height: 35px
      line-height: 35px
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis
      +placeholder()
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 16px
        letter-spacing: 0.02em
        color: #A3AED0

.drop-select-side

  .drop-button
    padding-top: 0
    padding-bottom: 0

  .drop-overflow
    max-height: none
    padding: 10px

    ul.action-group-list
      display: flex
      overflow: visible
      flex-direction: column
      align-items: stretch
      justify-content: flex-start
      list-style-type: none
      padding: 0
      margin: 0
      box-shadow: none
      li
        display: block
        width: 100%
        height: auto
        margin: 0
        & + li
          margin-top: 10px

.side-items
  list-style-type: none
  padding: 0
  margin: 0
  display: flex
  // max-width: 180px
  // overflow: hidden

.side-item
  display: none
  align-items: center
  margin-right: 5px
  &.is-active
    display: flex
  &__box
    display: block
    position: relative
    overflow: hidden
    width: 25px
    height: 25px
    flex: 0 0 25px
    margin-right: 5px
    background: #FFFFFF
    box-shadow: 0px 2px 8px 2px rgba(204, 210, 227, 0.6)
    border-radius: 6px
  &__title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #5B6485

    display: none

.side-item.is-active
  .side-item__box
    &::before
      content: ''
      display: block
      position: absolute
      background-color: $accent
  &.--top
    .side-item__box
      &::before
        top: 0
        left: 0
        width: 100%
        height: 5px
  &.--left
    .side-item__box
      &::before
        top: 0
        right: 0
        width: 5px
        height: 100%
  &.--bottom
    .side-item__box
      &::before
        bottom: 0
        left: 0
        width: 100%
        height: 5px
  &.--right
    .side-item__box
      &::before
        top: 0
        right: 0
        width: 5px
        height: 100%
  &.--all
    .side-item__box
      &::before
        background: none
        width: 100%
        height: 100%
        border: 5px solid $accent
    .side-item__title
      display: block!important

.table-head.--documents
  div:last-child
    flex-grow: 1
    .drop
      width: 100%
      max-width: 350px

.submenu-wrap
  +r(374)
    width: 100%
    max-width: 100%
  li,
  li:first-child
    a
      +r(374)
        justify-content: flex-start
      &::after
        +r(374)
          margin-left: auto
      sup
        +r(374)
          position: static

.accordion-furniture
  padding: 10px
  background: #fff
  .accordion-item
    border: none
    border-bottom: 1px solid #F7F8FB
    &:last-child
      border-bottom: none
  .accordion-header
    &.is-show
      background: #E4E7EF
      .table-cell
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #5B6485
        +r(767)
          font-size: 12px
  .accordion-body
    padding: 0
    background: #F7F8FB
    .footable
      background: none
      thead
        tr.footable-header
          background: none!important
          border-bottom: 1px solid #E4E7EF
          th
            font-style: normal
            font-weight: 500
            font-size: 12px
            line-height: 100%
            letter-spacing: 0.02em
            color: #A3AED0
        tr th:first-child
          padding-left: 10px
        tr th:last-child
          padding-right: 10px
      tbody
        border: none
        tr td:first-child::before,
        tr td:last-child::after
          display: none
        tr td:first-child
          padding-left: 10px
        tr td:last-child
          padding-right: 10px
        tr
          td
            .form-control
              display: inline-block
              width: 100px

.table-row
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center
.table-cell
  display: flex
  align-items: center
  padding: 10px
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #5B6485
  +r(767)
    font-size: 12px
    padding: 10px
  &:first-child
    flex: 0 0 340px
    +r(767)
      flex: 0 0 50%
      flex-grow: 1
  &:nth-child(2)
    flex-grow: 1
    +r(767)
      flex: 0 0 auto
  &:last-child
    flex-direction: row-reverse
    +r(767)
      flex: 0 0 100px

    .btn-icon
      width: 35px
      height: 35px
      flex: 0 0 35px

    .ico_arrow-down
      margin-left: 10px
      font-size: 15px
      +r(767)
        margin-left: 5px
      &[aria-expanded="true"]
        transform: rotate(180deg)
        color: $accent
        & + .btn-icon
          display: block
      & + .ico_plus
        background: $accent
        color: #fff
        display: none

.accordion-furniture-head
  background: #5B6485

  .table-cell
    color: #fff
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #E4E7EF
    min-height: 40px
    +r(767)
      font-size: 12px
    &:first-child
      padding-left: 20px
    &:last-child
      padding-right: 20px
