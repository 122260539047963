

.widjet-mesages-list
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: flex
    justify-content: space-between
    align-items: flex-end
    position: relative
    padding: 4px 0 4px 25px
    &:before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 4px
      height: 100%
      background-color: currentColor
      box-shadow: 1px 0px 4px 1px rgba(34, 131, 255, 0.3)
    &:nth-child(1)
      color: #73CD9B
    &:nth-child(2)
      color: #796BC2
    &:nth-child(3)
      color: #628EFF

    & + .widjet-mesages-list__item
      margin-top: 12px
  &__info
    flex-grow: 1
  &__title
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
  &__price
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #5B6485
  &__date
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    text-align: right
    letter-spacing: 0.04em
    color: #A3AED0