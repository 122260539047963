.page-constructor
  .page-content
    padding-top: 30px
    padding-bottom: 50px

.constructor-offcanvas-btn
  position: fixed
  bottom: 250px
  left: 10px
  button
    display: flex
    justify-content: center
    align-items: center
    background: $accent
    color: #fff
    font-size: 20px
    text-align: center
    width: 50px
    height: 50px
    flex: 0 0 50px
    border: none
    border-radius: 8px

.constructor-offcanvas
  display: block
  position: fixed
  top: 0
  left: 0
  width: 500px
  height: 100vh
  z-index: 10
  background: #fff
  box-shadow: 0 0 20px 20px rgba(#000, 0.15)
  padding: 30px
.constructor-offcanvas-box
  display: block
  position: relative
  height: calc( 100% - 60px )
  overflow-y: auto
ul.constructor-offcanvas-menu
  list-style-type: none
  padding: 0
  margin: 0
  li
    & + li
      margin-top: 10px
    a
      color: #5B6485
      font-size: 18px
      font-style: normal
      font-weight: 500
      letter-spacing: 0.02em
      line-height: 100%

.constructor
  display: block
  position: relative
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 30px
  &__title
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485
  &__btns
    display: flex
    justify-content: space-between
    align-items: center
  &__btns-steps
    display: flex
    align-items: center
    position: relative
    background: #FFFFFF
    box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
    border-radius: 8px
    &::before
      content: ''
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: 1px
      height: 25px
      background: #E4E7EF
    .constructor__btn
      box-shadow: none
      &::before
        font-size: 16px
        margin-left: -3px
      & + .constructor__btn
        margin-left: 5px
  &__btns-group
    display: flex
    align-items: center
    margin-left: 60px
  &__btn
    display: flex
    justify-content: center
    align-items: center
    min-width: 35px
    height: 35px
    border: none
    background: #FFFFFF
    border-radius: 8px
    box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
    box-sizing: border-box
    padding: 0 6px
    +tr(0.25s)
    +hh
      &::before
        color: #628EFF
    &::before
      color: #CCD2E3
      font-size: 19px
      margin-right: 2px
      +tr(0.25s)
    & + .constructor__btn
      margin-left: 10px
    &.ico_size
      font-style: normal
      font-weight: 600
      font-size: 12px
      line-height: 100%
      letter-spacing: 0.01em
      color: #A3AED0
      &::before
        margin-right: 3px
        font-size: 24px
  &__body
    display: block
    position: relative
    &::after
      content: ''
      display: block
      position: absolute
      top: 0
      left: 60px
      width: calc( 100% - 60px )
      height: 100%
      background: #fff
      border-radius: 30px
  &__panel
    display: block
    width: 375px
    height: 420px
    position: absolute
    top: 0
    left: 0
    z-index: 1
    background: #E4E7EF
    border-radius: 30px
    &:after
      content: ''
      display: block
      width: calc( 100% - 60px )
      height: calc( 100% - 60px )
      position: absolute
      top: 60px
      left: 60px
      background: #fff
      border-radius: 16px 0 0 0
      z-index: 1
      pointer-events: none
  &__info
    display: flex
    align-items: center
    padding: 0 10px 0 23px
    height: 60px
  &__type-processing
    width: 60px
    padding-top: 30px
    position: relative

  &__footer
    margin-top: 30px
    display: flex
    justify-content: flex-end
    align-items: center

  .info-list
    list-style-type: none
    padding: 0
    margin: 0
    display: flex
    align-items: center
    &__item
      display: block
      position: relative
      margin-right: 15px
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 100%
      color: #5B6485
      &:last-child
        margin-right: 0

  .type-processing
    display: flex
    flex-direction: column
    list-style-type: none
    padding: 0 0 20px 0
    margin: 0
    &__item
      width: 60px
      height: 60px
      flex: 0 0 60px
      &:first-child
        .type-processing__link:before
          display: none
    &__link
      width: 60px
      height: 60px
      flex: 0 0 60px
      display: flex
      justify-content: center
      align-items: center
      position: relative
      background-position: center
      background-repeat: no-repeat
      background-size: 25px 25px
      &:after
        content: ''
        display: block
        position: absolute
        top: 0
        right: -3px
        width: 3px
        height: 60px
        background: #628EFF
        opacity: 0
        z-index: 5

      &.active
        &:after
          opacity: 1
    &__item

      &.--type-processing-1
        .type-processing__link
          background-image: url(../img/type-processing-1-1.svg)
          +h
            background-image: url(../img/type-processing-1-2.svg)
          &.active
            background-image: url(../img/type-processing-1-3.svg)

      &.--type-processing-2
        .type-processing__link
          background-image: url(../img/type-processing-2-1.svg)
          +h
            background-image: url(../img/type-processing-2-2.svg)
          &.active
            background-image: url(../img/type-processing-2-3.svg)

      &.--type-processing-3
        .type-processing__link
          background-image: url(../img/type-processing-3-1.svg)
          +h
            background-image: url(../img/type-processing-3-2.svg)
          &.active
            background-image: url(../img/type-processing-3-3.svg)

      &.--type-processing-5
        .type-processing__link
          background-image: url(../img/type-processing-4-1.svg)
          +h
            background-image: url(../img/type-processing-4-2.svg)
          &.active
            background-image: url(../img/type-processing-4-3.svg)

      &.--type-processing-4
        .type-processing__link
          background-image: url(../img/type-processing-5-1.svg)
          +h
            background-image: url(../img/type-processing-5-2.svg)
          &.active
            background-image: url(../img/type-processing-5-3.svg)


.constructor-box
  display: flex
  align-items: stretch
  position: relative
  margin-left: 60px
  border-radius: 16px
  min-height: 810px
  z-index: 1

  &__sidebar
    display: flex
    align-items: stretch
    position: relative
    width: 315px
    margin: 90px 0 30px 0

  &__sidebar-form
    width: 73px
    border-right: 1px solid #E4E7EF

  &__sidebar-content
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 0 20px
    border-right: 1px solid #E4E7EF
    flex-grow: 1

    &.hidden
      opacity: 0
      pointer-events: none

    .sidebar-title
      display: flex
      justify-content: center
      align-items: center
      height: 25px
      background: #E4E7EF
      margin-bottom: 10px

      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485


  &__content
    flex-grow: 1
    margin: 29px 25px 20px 25px
    background-image: url(../img/coordinats.svg)
    background-position: left bottom
    background-size: contain
    background-repeat: no-repeat

    .full-size
      display: flex
      justify-content: center
      align-items: center
      width: 24px
      height: 24px
      flex: 0 0 24px
      font-size: 24px
      border: none
      position: absolute
      top: 20px
      right: 15px
      color: #CCD2E3
      +icon(full)
      +tr(0.25s)
      +h
        color: $accent

    .change-texture
      display: flex
      justify-content: center
      align-items: center
      width: 24px
      height: 24px
      flex: 0 0 24px
      font-size: 20px
      border: none
      position: absolute
      top: 50px
      right: 15px
      color: #CCD2E3
      +icon(color)
      +tr(0.25s)
      +h
        color: $accent

.constructor-form
  list-style-type: none
  padding: 0
  margin: 0 0 20px 0
  display: none
  &.is-active
    display: block
  &__item
    display: block
    position: relative
    margin-bottom: 12px
    .ico_form_6
      font-size: 14px
  &__link
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    position: relative
    padding: 0 20px

    & > span
      font-size: 22px
      color: #CCD2E3
      +tr(0.25s)
      +h
        color: $accent
      &:before
        width: auto
    &.active
      & > span
        color: $accent
  &__more
    display: flex
    justify-content: center
    align-items: center
    width: 30px
    height: 30px
    flex: 0 0 30px
    margin: 0 auto
    border: none
    box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
    background: #628EFF
    border-radius: 8px
    color: #fff
    font-size: 10px

.constructor-options
  display: block
  margin-bottom: 20px
  flex-grow: 1

.constructor-options-box
  height: 100%
  max-height: 400px
  overflow-y: auto
  margin-right: -20px
  padding-right: 15px
  margin-bottom: 10px

  scrollbar-color: #CCD2E3 #fff
  scrollbar-width: thin!important
  -moz-appearance: none !important

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: #fff

  &::-webkit-scrollbar-thumb
    background-color: #CCD2E3

.constructor-options-more
  display: flex
  justify-content: space-between
  align-items: center
  button
    display: flex
    justify-content: center
    align-items: center
    width: 30px
    height: 30px
    flex: 0 0 30px
    margin: 0 auto
    border: none
    box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
    background: #628EFF
    border-radius: 8px
    color: #fff
    font-size: 10px
  &:before,
  &:after
    content: ''
    display: block
    width: 65px
    height: 1px
    background: #E4E7EF

.constructor-operation
  display: block
  margin-top: auto

.constructor-operation-box
  height: 168px
  overflow-y: auto
  margin-right: -20px
  padding-right: 15px

  scrollbar-color: #CCD2E3 #fff
  scrollbar-width: thin!important
  -moz-appearance: none !important

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: #fff

  &::-webkit-scrollbar-thumb
    background-color: #CCD2E3

.operation-item
  display: flex
  justify-content: flex-start
  align-items: center
  background: #F7F8FB
  padding: 8px
  border: 1px solid #F7F8FB
  box-sizing: border-box
  +tr(0.25s)
  +h
    border: 1px solid #628EFF
  & + .operation-item
    margin-top: 2px
  &__numb
    display: flex
    justify-content: center
    align-items: center
    width: 15px
    height: 15px
    flex: 0 0 15px
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 100%
    text-align: center
    letter-spacing: 0.02em
    color: #628EFF
  &__ico
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    flex: 0 0 35px
    background: #fff
    color: #B7BFD9
    font-size: 15px
    margin: 0 5px
  &__desc
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    color: #5B6485
    margin-right: 5px
  &__del
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    flex: 0 0 20px
    margin: 0
    padding: 0
    border-radius: 50%
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23CCD2E3'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
    background-color: #FFFFFF
    background-size: 8px
    box-shadow: 0px 4px 5px 0 rgba(189, 207, 244, 50%)
    cursor: pointer
    +tr(0.25s)
    +h
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23F00'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
      background-color: #FFFFFF
      background-size: 8px
      box-shadow: 0px 1px 3px 0 rgba(189, 207, 244, 50%)

.constructor-options-box
  ul.nav.nav-pills
    background: #F7F8FB
    border-radius: 8px
    margin-bottom: 11px
    .nav-item
      width: 50%
    .nav-link
      width: 100%
      display: flex
      justify-content: center
      align-items: center
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      text-align: center
      letter-spacing: 0.02em
      color: #5B6485
      border: 2px solid transparent
      border-radius: 8px
      padding: 0 5px
      height: 32px
      box-sizing: border-box
      +tr(0.25s)
      &.active
        background: #FFFFFF
        border: 2px solid #628EFF
        box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)

.constructor-form-group
  margin-bottom: 10px

.constructor-text-field
  display: flex
  align-items: center
  justify-content: space-between
  & > span
    display: block
    margin-right: 10px
    width: calc(100% - 90px)
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485
  input
    display: block
    width: 90px
    flex: 0 0 90px
    height: 32px
    border: 1.5px solid #E4E7EF
    border-radius: 8px
    padding: 0 12px
    +line(1)
    +placeholder()
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.001em
      color: #A3AED0

.constructor-checkbox-field
  display: flex
  align-items: center
  justify-content: flex-start
  & > span
    display: block
    margin-right: 10px
    width: calc(100% - 100px)
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485

.constructor-sides-field,
.constructor-angles-field
  display: flex
  flex-direction: column
  & > span
    display: block
    margin-bottom: 8px
    width: 100%
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485

  .sides > *,
  .angles > *
    margin: 0 10px 0 0

.constructor-radio-fields
  display: flex
  flex-direction: column
  & > span
    display: block
    margin-bottom: 8px
    width: 100%
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485

.constructor-radio-group
  display: flex
  justify-content: space-between
  align-items: center
  background: #F7F8FB
  border-radius: 8px
  label
    width: 33%
    height: 32px
    text-align: center
    cursor: pointer
  input[type="radio"]
    display: none
  span
    width: 100%
    height: 32px
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    background: transparent
    border: 2px solid transparent
    border-radius: 8px
  input[type="radio"]:checked + span
    background: #fff
    border: 2px solid $accent

  &.--value-2
    label
      width: 50%

.constructor-select-field
  display: flex
  align-items: center
  justify-content: space-between
  & > span
    display: block
    margin-right: 10px
    width: calc(100% - 90px)
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.01em
    color: #5B6485
  .drop
    min-width: 90px
  .drop-button
    display: block
    flex: 0 0 90px
    width: 90px
    height: 32px
    padding: 0 12px
    overflow: hidden
    border: 1.5px solid #E4E7EF
    border-radius: 8px
    text-overflow: ellipsis
    white-space: nowrap
    background-position: right 5px center
    background-size: 12px
  .drop-clear
    width: 29px
    height: 29px
    flex: 0 0 29px
    background-color: #fff
    background-size: 12px
    background-position: right 5px center
    top: 1.5px
    right: 1.5px

  .dropdown
    min-width: 90px

  .dropdown-toggle
    display: flex
    justify-content: space-between
    align-items: center
    flex: 0 0 90px
    width: 90px
    height: 32px
    padding: 0 12px
    overflow: hidden
    border: 1.5px solid #E4E7EF
    border-radius: 8px
    text-overflow: ellipsis
    white-space: nowrap
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23A3AED0' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E")
    background-repeat: no-repeat
    background-position: right 10px center
    background-size: 10px
    &.show
      border-radius: 8px 8px 0 0
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23A3AED0' stroke-width='1.03' points='4 13 10 7 16 13'%3E%3C/polyline%3E%3C/svg%3E")
      background-repeat: no-repeat
      background-position: right 10px center
      background-size: 10px

  .dropdown-toggle:after
    display: none

  .dropdown-menu
    max-width: 90px
    min-width: 90px
    margin-top: -5px!important
    border-radius: 0 0 8px 8px
    border: 1.5px solid #E4E7EF

    max-height: 100px
    overflow-y: auto

    scrollbar-color: $accent #E2EBEC
    scrollbar-width: thin!important
    -moz-appearance: none !important

    &::-webkit-scrollbar
      width: 4px

    &::-webkit-scrollbar-track
      background-color: #E2EBEC

    &::-webkit-scrollbar-thumb
      background-color: $accent

    li
      display: block
      position: relative
      padding: 5px 15px
      font-size: 14px
      display: block
      position: relative
      color: #000
      cursor: pointer
      border-bottom: 1px solid #F7F8FB
      +tr(0.3s)
      +h
        background: #F7F8FB
      a
        display: block
        font-size: 14px
        display: block
        position: relative
        color: #000
        +tr(0.3s)
        +h
          color: $accent
          text-decoration: none


.tooltip
  &.bs-tooltip-end
    left: -6px!important
    .tooltip-inner
      border-left: 3px solid #628EFF
  &.bs-tooltip-top
    .tooltip-inner
      border-bottom: 3px solid #628EFF
  &.show
    opacity: 1
  .tooltip-arrow
    display: none
  .tooltip-inner
    padding: 5px 10px
    background: #fff
    border-radius: 0
    color: #5B6485
    font-size: 12px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.01em
    line-height: 100%
    text-align: left
    opacity: 1
    box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 60%)

.constructor__menu-title
  display: flex
  justify-content: center
  align-items: center
  font-style: normal
  font-weight: 500
  font-size: 10px
  line-height: 100%
  color: #5B6485
  padding: 6px 5px
  margin-bottom: 10px