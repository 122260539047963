
.footable
  tbody
    tr.message-canceled,
    tr.message-confirmed
      td
        img
          max-width: 18px

.footable
  tbody
    tr.message-canceled
      td
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #A3AED0
        +r(1199)
          font-size: 12px
        +r(767)
          font-size: 11px

    tr.message-confirmed
      td
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 130%
        letter-spacing: 0.02em
        color: #5B6485
        +r(1199)
          font-size: 12px
        +r(767)
          font-size: 11px
        a
          display: block
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #2283FF
          margin-bottom: 5px
          +r(1199)
            font-size: 12px
          +r(767)
            font-size: 11px


.massages-box
  background: #FFFFFF
  border-radius: 10px
  padding: 30px 0 30px 30px
  margin-bottom: 5px
  max-width: 1106px
  margin-left: auto
  margin-right: auto

  +r(1199)
    padding: 20px 0 20px 20px
  +r(767)
    padding: 0 0 20px 0
    border-radius: 0
    background: none

.massages-overflow
  max-height: 575px
  padding-right: 30px
  overflow: auto
  scrollbar-color: rgba(163, 174, 208, 0.2) #FFFFFF
  scrollbar-width: thin!important
  -moz-appearance: none !important
  +r(1199)
    padding-right: 20px
  +r(767)
    max-height: none
    padding-right: 0
    overflow: visible
  &::-webkit-scrollbar
    width: 4px
  &::-webkit-scrollbar-track
    background-color: #FFFFFF
  &::-webkit-scrollbar-thumb
    background-color: rgba(163, 174, 208, 0.2)

.massage-item
  display: flex
  & + .massage-item
    margin-top: 20px
  &__user
    width: 70px
    height: 70px
    flex: 0 0 70px
    border-radius: 50%
    overflow: hidden
    +r(767)
      width: 40px
      height: 40px
      flex: 0 0 40px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__info
    max-width: 600px
    margin: 0 20px
    +r(767)
      margin: 0 10px
  &__text
    display: inline-block
    padding: 20px 30px
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.02em
    +r(767)
      padding: 10px
      font-size: 11px
    & + .massage-item__text
      margin-top: 20px
  &__date
    display: block
    position: relative
    margin-top: 10px
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0

  &.--question
    justify-content: flex-start
    .massage-item__text
      color: #fff
      background: linear-gradient(94.34deg, #6F9BF3 0%, #628EFF 96.55%)
      box-shadow: 4px 4px 15px -10px rgba(111, 155, 243, 0.3), 8px 8px 30px -10px rgba(111, 155, 243, 0.3), 10px 10px 50px rgba(111, 155, 243, 0.1)
      border-radius: 0px 8px 8px 8px

  &.--answer
    justify-content: flex-start
    flex-direction: row-reverse
    .massage-item__text
      color: #707C97
      background: #FFFFFF
      border: 1px solid #E4E7EF
      box-sizing: border-box
      box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05), 15px 15px 35px rgba(112, 124, 151, 0.05), 10px 10px 50px rgba(112, 124, 151, 0.03)
      border-radius: 8px 0px 8px 8px
    .massage-item__date
      text-align: right


.massages-form
  display: flex
  justify-content: space-between
  background: #FFFFFF
  border-radius: 10px
  padding: 30px
  max-width: 1106px
  margin-left: auto
  margin-right: auto
  +r(575)
    background: none
    border-radius: 0
    padding: 0
    flex-direction: column
  .btn
    margin-left: 30px
    +r(575)
      margin-left: 0
      margin-top: 10px
      width: 100%
