.action-group
  display: inline-block!important
  position: relative
  width: 35px
  height: 35px
  text-align: center

  &.is-show
    .action-group-drop
      visibility: visible
      opacity: 1
      pointer-events: all

.action-group-btn
  padding: 0
  display: flex
  justify-content: center
  align-items: center
  width: 35px
  height: 35px
  flex: 0 0 35px
  color: #CCD2E3
  cursor: pointer
  font-size: 10px
  background-color: #fff
  box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
  border-radius: 8px
  font-size: 15px
  +tr(0.3s)
  &:hover,
  &:focus
    background-color: #fff
    color: #A3AED0
  &:active
    background-color: #fff
    color: #FF6A6A

.action-group-drop
  -webkit-transition: 0.5s
  -o-transition: 0.5s
  display: block
  visibility: hidden
  z-index: 3
  position: absolute
  top: -12px
  right: -9px
  opacity: 0
  pointer-events: none
  transition: 0.5s
  overflow: hidden
  box-sizing: border-box
  background: #FFFFFF
  box-shadow: 0px 0px 20px -1px rgba(163, 174, 208, 0.3), 0px 5px 15px -1px rgba(163, 174, 208, 0.3)
  border-radius: 8px

.action-group-list
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-box-align: center
  -ms-flex-align: center
  -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16)
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex
  align-items: center
  justify-content: center
  margin: 0
  padding: 10px 5px
  overflow: hidden
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16)
  list-style-type: none


  li
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-box-align: center
    -ms-flex-align: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: center
    width: 35px
    height: 35px
    +tr(0.3s)
    margin: 0 5px

    &.is-active
      button
        background-color: #929DA5
        color: #fff

    button,
    a
      width: 35px
      height: 35px
      flex: 0 0 35px
      display: flex
      justify-content: center
      align-items: center
      position: relative
      border: 0
      pointer-events: all
      cursor: pointer
      box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
      border-radius: 8px
      font-size: 20px
      color: #CCD2E3
      +tr(0.3s)
      &:hover,
      &:focus
        color: #A3AED0!important
        box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)!important
      &:active
        color: #FF6A6A!important
        box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)!important

      .ico_edit
        font-size: 15px

      .ico_close
        font-size: 16px

    &:last-child
      button,
      a
        background-color: #fff
        box-shadow: none
        &:hover,
        &:focus
          color: #FF6A6A!important
          box-shadow: none!important