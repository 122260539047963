
.submenu-box
  display: flex
  align-items: center
  position: relative
  z-index: 9
  +r(575)
    justify-content: space-between
.submenu-title
  display: block
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #A3AED0
  margin-right: 10px
  +r(1359)
    max-width: calc(100% - 170px)
    +line(1)
.submenu-wrap
  display: block
  position: relative
  +r(1359)
    height: 45px
    min-width: 175px
.submenu
  display: flex
  align-items: center
  list-style-type: none
  padding: 0
  margin: 0
  li
    & + li
      margin-left: 10px
    a
      display: inline-flex
      justify-content: center
      align-items: center
      padding: 0 15px
      background: #E4E7EF
      border-radius: 8px
      height: 45px

      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485

      +tr(0.3s)
      +h
        color: #fff
        background: #CCD2E3
        box-shadow: 0px 8px 10px -10px rgba(89, 182, 137, 0.3)

      sup
        display: flex
        justify-content: center
        align-items: center
        width: 20px
        height: 20px
        background: rgba(163, 174, 208, 0.7)
        border-radius: 15px
        color: #fff
        font-style: normal
        font-weight: 600
        font-size: 10px
        line-height: 12px
        text-align: center
        letter-spacing: 0.02em
        color: #FFFFFF
        margin-left: 5px

      &.active
        color: #fff
        background: #59B689
        box-shadow: 0px 8px 10px -10px rgba(89, 182, 137, 0.3)
        sup
          background: rgba(91, 100, 133, 0.5)

