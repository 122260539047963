.page-leader
  &__select-date
    display: flex
    justify-content: flex-start
    align-items: center
    margin-bottom: 30px

    +r(767)
      flex-direction: column
      align-items: stretch

    & > div:nth-child(1),
    & > div:nth-child(2)
      flex-grow: 1
      max-width: 350px
      margin-right: 30px
      +r(767)
        max-width: 100%
        margin: 0 0 10px 0

    .btn
      +r(767)
        width: 100%

.widjet
  &.--value
    .widjet__head
      min-height: 54px
    .widjet__body
      padding-top: 23px
      padding-bottom: 42px
      position: relative
      z-index: 2
      .value
        font-style: normal
        font-weight: bold
        font-size: 60px
        line-height: 100%
        text-align: center
        letter-spacing: 0.02em
        color: #5B6485

        sup
          font-style: normal
          font-weight: bold
          font-size: 18px
          line-height: 100%
          text-align: center
          letter-spacing: 0.02em
          color: #5B6485
          top: -35px

        sub
          font-style: normal
          font-weight: bold
          font-size: 18px
          line-height: 100%
          letter-spacing: 0.02em
          color: #5B6485
          bottom: 0

  &.--leader-new-users
    &::before
      content: ''
      display: block
      position: absolute
      left: 0
      bottom: 0
      width: 108px
      height: 187px
      background-image: url(../img/bg_widget-leader-new-users.svg)
      z-index: 1

  &.--leader-returns
    &::before
      content: ''
      display: block
      position: absolute
      right: 0
      bottom: 0
      width: 119px
      height: 150px
      background-image: url(../img/bg_widget-leader-returns.svg)
      z-index: 1

  &.--leader-visiting
    &::before
      content: ''
      display: block
      position: absolute
      right: 0
      bottom: 0
      width: 254px
      height: 82px
      background-image: url(../img/bg_widget-leader-visiting.svg)
      z-index: 1

  &.--leader-time
    &::before
      content: ''
      display: block
      position: absolute
      right: 0
      bottom: 0
      width: 254px
      height: 130px
      background-image: url(../img/bg_widget-leader-time.svg)
      z-index: 1

  &.--leader-popular-products
    &::before
      content: ''
      display: block
      position: absolute
      left: 0
      bottom: 0
      width: 262px
      height: 204px
      background-image: url(../img/bg_widget-leader-popular-products.svg)
      z-index: 1
      +r(1199)
        background-image: none


.leader-orders-info
  display: flex
  justify-content: space-between
  align-items: flex-start
  list-style-type: none
  padding: 0
  margin: 0
  +r(479)
    flex-direction: column
    align-items: center
  &__item
    display: flex
    flex-direction: column
    align-items: flex-start
    +r(479)
      align-items: center
  &__title
    font-style: normal
    font-weight: normal
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485
    margin-bottom: 5px
  &__value
    font-style: normal
    font-weight: bold
    font-size: 60px
    line-height: 100%
    text-align: center
    letter-spacing: 0.02em
    color: #5B6485
    margin-bottom: 7px
    sup
      font-style: normal
      font-weight: bold
      font-size: 18px
      line-height: 100%
      text-align: center
      letter-spacing: 0.02em
      color: #5B6485
      top: -35px

    sub
      font-style: normal
      font-weight: bold
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
      bottom: 0
  &__img
    display: block
    position: relative

.widjet-complaints-list
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    & + .widjet-complaints-list__item
      margin-top: 20px

    &:nth-child(3n + 1)
      .widjet-complaints-list__info:before
        background: #73CD9B

    &:nth-child(3n + 2)
      .widjet-complaints-list__info:before
        background: #796BC2

    &:nth-child(3n + 3)
      .widjet-complaints-list__info:before
        background: #628EFF
  &__info
    display: block
    position: relative
    padding-left: 24px
    &::before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 4px
      height: 100%
      box-shadow: 1px 0px 4px 1px rgba(115, 205, 155, 0.3)
  &__title
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
  &__date
    display: block
    position: relative
    margin: 0 5px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
    margin-top: 4px