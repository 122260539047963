@font-face
    font-family: "icons"
    src: url('../fonts/icons.eot')
    src: url('../fonts/icons.eot?#iefix') format('eot'), url('../fonts/icons.woff') format('woff'),url('../fonts/icons.woff2') format('woff2'), url('../fonts/icons.ttf') format('truetype'), url('../fonts/icons.svg#icons') format('svg')


@mixin icon-styles
    font-family: "icons"
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-style: normal
    font-variant: normal
    font-weight: normal
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none
    text-transform: none

    display: inline-block
    text-decoration: inherit
    width: 1em
    line-height: 1em


%icon
    +icon-styles

@function icon-char($filename)
    $char: ""

    @if $filename == apple
        $char: "\E001"
    
    @if $filename == arrow-down
        $char: "\E002"
    
    @if $filename == arrow-right
        $char: "\E003"
    
    @if $filename == arrow-up
        $char: "\E004"
    
    @if $filename == back
        $char: "\E005"
    
    @if $filename == bank
        $char: "\E006"
    
    @if $filename == calendar
        $char: "\E007"
    
    @if $filename == cancel
        $char: "\E008"
    
    @if $filename == cart
        $char: "\E009"
    
    @if $filename == chat
        $char: "\E00A"
    
    @if $filename == check
        $char: "\E00B"
    
    @if $filename == checkmark
        $char: "\E00C"
    
    @if $filename == chevron-down
        $char: "\E00D"
    
    @if $filename == chevron-left
        $char: "\E00E"
    
    @if $filename == chevron-right
        $char: "\E00F"
    
    @if $filename == chevron-up
        $char: "\E010"
    
    @if $filename == close
        $char: "\E011"
    
    @if $filename == color
        $char: "\E012"
    
    @if $filename == copy
        $char: "\E013"
    
    @if $filename == diia
        $char: "\E014"
    
    @if $filename == drag
        $char: "\E015"
    
    @if $filename == edit
        $char: "\E016"
    
    @if $filename == facebook
        $char: "\E017"
    
    @if $filename == file-cancel
        $char: "\E018"
    
    @if $filename == file-import
        $char: "\E019"
    
    @if $filename == file-success
        $char: "\E01A"
    
    @if $filename == file
        $char: "\E01B"
    
    @if $filename == filter
        $char: "\E01C"
    
    @if $filename == fire
        $char: "\E01D"
    
    @if $filename == folder
        $char: "\E01E"
    
    @if $filename == form_1
        $char: "\E01F"
    
    @if $filename == form_10
        $char: "\E020"
    
    @if $filename == form_2
        $char: "\E021"
    
    @if $filename == form_3
        $char: "\E022"
    
    @if $filename == form_4
        $char: "\E023"
    
    @if $filename == form_5
        $char: "\E024"
    
    @if $filename == form_6
        $char: "\E025"
    
    @if $filename == form_7
        $char: "\E026"
    
    @if $filename == form_8
        $char: "\E027"
    
    @if $filename == form_9
        $char: "\E028"
    
    @if $filename == full
        $char: "\E029"
    
    @if $filename == google
        $char: "\E02A"
    
    @if $filename == grid
        $char: "\E02B"
    
    @if $filename == import
        $char: "\E02C"
    
    @if $filename == info
        $char: "\E02D"
    
    @if $filename == instagram
        $char: "\E02E"
    
    @if $filename == kriv_1
        $char: "\E02F"
    
    @if $filename == kriv_10
        $char: "\E030"
    
    @if $filename == kriv_11
        $char: "\E031"
    
    @if $filename == kriv_12
        $char: "\E032"
    
    @if $filename == kriv_13
        $char: "\E033"
    
    @if $filename == kriv_2
        $char: "\E034"
    
    @if $filename == kriv_3
        $char: "\E035"
    
    @if $filename == kriv_4
        $char: "\E036"
    
    @if $filename == kriv_5
        $char: "\E037"
    
    @if $filename == kriv_6
        $char: "\E038"
    
    @if $filename == kriv_7
        $char: "\E039"
    
    @if $filename == kriv_8
        $char: "\E03A"
    
    @if $filename == kriv_9
        $char: "\E03B"
    
    @if $filename == location
        $char: "\E03C"
    
    @if $filename == marker
        $char: "\E03D"
    
    @if $filename == menu-1
        $char: "\E03E"
    
    @if $filename == menu-2
        $char: "\E03F"
    
    @if $filename == menu-3
        $char: "\E040"
    
    @if $filename == menu-4
        $char: "\E041"
    
    @if $filename == menu-5
        $char: "\E042"
    
    @if $filename == menu-6
        $char: "\E043"
    
    @if $filename == menu-7
        $char: "\E044"
    
    @if $filename == menu-tool-1
        $char: "\E045"
    
    @if $filename == menu-tool-2
        $char: "\E046"
    
    @if $filename == menu-tool-3
        $char: "\E047"
    
    @if $filename == menu-tool-4
        $char: "\E048"
    
    @if $filename == menu
        $char: "\E049"
    
    @if $filename == modal
        $char: "\E04A"
    
    @if $filename == move
        $char: "\E04B"
    
    @if $filename == plus
        $char: "\E04C"
    
    @if $filename == post_1
        $char: "\E04D"
    
    @if $filename == post_2
        $char: "\E04E"
    
    @if $filename == post_3
        $char: "\E04F"
    
    @if $filename == post_4
        $char: "\E050"
    
    @if $filename == post_5
        $char: "\E051"
    
    @if $filename == print
        $char: "\E052"
    
    @if $filename == raz_1
        $char: "\E053"
    
    @if $filename == raz_2
        $char: "\E054"
    
    @if $filename == raz_3
        $char: "\E055"
    
    @if $filename == raz_4
        $char: "\E056"
    
    @if $filename == raz_5
        $char: "\E057"
    
    @if $filename == raz_6
        $char: "\E058"
    
    @if $filename == refresh
        $char: "\E059"
    
    @if $filename == reload
        $char: "\E05A"
    
    @if $filename == return
        $char: "\E05B"
    
    @if $filename == search
        $char: "\E05C"
    
    @if $filename == setting
        $char: "\E05D"
    
    @if $filename == size
        $char: "\E05E"
    
    @if $filename == star
        $char: "\E05F"
    
    @if $filename == submenu
        $char: "\E060"
    
    @if $filename == success
        $char: "\E061"
    
    @if $filename == sverl_1
        $char: "\E062"
    
    @if $filename == sverl_2
        $char: "\E063"
    
    @if $filename == sverl_3
        $char: "\E064"
    
    @if $filename == sverl_4
        $char: "\E065"
    
    @if $filename == sverl_5
        $char: "\E066"
    
    @if $filename == sverl_6
        $char: "\E067"
    
    @if $filename == tool-1
        $char: "\E068"
    
    @if $filename == tool-10
        $char: "\E069"
    
    @if $filename == tool-2
        $char: "\E06A"
    
    @if $filename == tool-3
        $char: "\E06B"
    
    @if $filename == tool-4
        $char: "\E06C"
    
    @if $filename == tool-5
        $char: "\E06D"
    
    @if $filename == tool-6
        $char: "\E06E"
    
    @if $filename == tool-7
        $char: "\E06F"
    
    @if $filename == tool-8
        $char: "\E070"
    
    @if $filename == tool-9
        $char: "\E071"
    
    @if $filename == trash
        $char: "\E072"
    
    @if $filename == type-processing-1
        $char: "\E073"
    
    @if $filename == type-processing-2
        $char: "\E074"
    
    @if $filename == type-processing-3
        $char: "\E075"
    
    @if $filename == type-processing-4
        $char: "\E076"
    
    @if $filename == type-processing-5
        $char: "\E077"
    
    @if $filename == upload
        $char: "\E078"
    
    @if $filename == user
        $char: "\E079"
    
    @if $filename == youtube
        $char: "\E07A"
    
    @return $char


@mixin icon($filename, $insert: before, $extend: true)
    &:#{$insert}
        @if $extend
            @extend %icon
        @else
            +icon-styles

        content: icon-char($filename)

.ico_apple
    +icon(apple)
.ico_arrow-down
    +icon(arrow-down)
.ico_arrow-right
    +icon(arrow-right)
.ico_arrow-up
    +icon(arrow-up)
.ico_back
    +icon(back)
.ico_bank
    +icon(bank)
.ico_calendar
    +icon(calendar)
.ico_cancel
    +icon(cancel)
.ico_cart
    +icon(cart)
.ico_chat
    +icon(chat)
.ico_check
    +icon(check)
.ico_checkmark
    +icon(checkmark)
.ico_chevron-down
    +icon(chevron-down)
.ico_chevron-left
    +icon(chevron-left)
.ico_chevron-right
    +icon(chevron-right)
.ico_chevron-up
    +icon(chevron-up)
.ico_close
    +icon(close)
.ico_color
    +icon(color)
.ico_copy
    +icon(copy)
.ico_diia
    +icon(diia)
.ico_drag
    +icon(drag)
.ico_edit
    +icon(edit)
.ico_facebook
    +icon(facebook)
.ico_file-cancel
    +icon(file-cancel)
.ico_file-import
    +icon(file-import)
.ico_file-success
    +icon(file-success)
.ico_file
    +icon(file)
.ico_filter
    +icon(filter)
.ico_fire
    +icon(fire)
.ico_folder
    +icon(folder)
.ico_form_1
    +icon(form_1)
.ico_form_10
    +icon(form_10)
.ico_form_2
    +icon(form_2)
.ico_form_3
    +icon(form_3)
.ico_form_4
    +icon(form_4)
.ico_form_5
    +icon(form_5)
.ico_form_6
    +icon(form_6)
.ico_form_7
    +icon(form_7)
.ico_form_8
    +icon(form_8)
.ico_form_9
    +icon(form_9)
.ico_full
    +icon(full)
.ico_google
    +icon(google)
.ico_grid
    +icon(grid)
.ico_import
    +icon(import)
.ico_info
    +icon(info)
.ico_instagram
    +icon(instagram)
.ico_kriv_1
    +icon(kriv_1)
.ico_kriv_10
    +icon(kriv_10)
.ico_kriv_11
    +icon(kriv_11)
.ico_kriv_12
    +icon(kriv_12)
.ico_kriv_13
    +icon(kriv_13)
.ico_kriv_2
    +icon(kriv_2)
.ico_kriv_3
    +icon(kriv_3)
.ico_kriv_4
    +icon(kriv_4)
.ico_kriv_5
    +icon(kriv_5)
.ico_kriv_6
    +icon(kriv_6)
.ico_kriv_7
    +icon(kriv_7)
.ico_kriv_8
    +icon(kriv_8)
.ico_kriv_9
    +icon(kriv_9)
.ico_location
    +icon(location)
.ico_marker
    +icon(marker)
.ico_menu-1
    +icon(menu-1)
.ico_menu-2
    +icon(menu-2)
.ico_menu-3
    +icon(menu-3)
.ico_menu-4
    +icon(menu-4)
.ico_menu-5
    +icon(menu-5)
.ico_menu-6
    +icon(menu-6)
.ico_menu-7
    +icon(menu-7)
.ico_menu-tool-1
    +icon(menu-tool-1)
.ico_menu-tool-2
    +icon(menu-tool-2)
.ico_menu-tool-3
    +icon(menu-tool-3)
.ico_menu-tool-4
    +icon(menu-tool-4)
.ico_menu
    +icon(menu)
.ico_modal
    +icon(modal)
.ico_move
    +icon(move)
.ico_plus
    +icon(plus)
.ico_post_1
    +icon(post_1)
.ico_post_2
    +icon(post_2)
.ico_post_3
    +icon(post_3)
.ico_post_4
    +icon(post_4)
.ico_post_5
    +icon(post_5)
.ico_print
    +icon(print)
.ico_raz_1
    +icon(raz_1)
.ico_raz_2
    +icon(raz_2)
.ico_raz_3
    +icon(raz_3)
.ico_raz_4
    +icon(raz_4)
.ico_raz_5
    +icon(raz_5)
.ico_raz_6
    +icon(raz_6)
.ico_refresh
    +icon(refresh)
.ico_reload
    +icon(reload)
.ico_return
    +icon(return)
.ico_search
    +icon(search)
.ico_setting
    +icon(setting)
.ico_size
    +icon(size)
.ico_star
    +icon(star)
.ico_submenu
    +icon(submenu)
.ico_success
    +icon(success)
.ico_sverl_1
    +icon(sverl_1)
.ico_sverl_2
    +icon(sverl_2)
.ico_sverl_3
    +icon(sverl_3)
.ico_sverl_4
    +icon(sverl_4)
.ico_sverl_5
    +icon(sverl_5)
.ico_sverl_6
    +icon(sverl_6)
.ico_tool-1
    +icon(tool-1)
.ico_tool-10
    +icon(tool-10)
.ico_tool-2
    +icon(tool-2)
.ico_tool-3
    +icon(tool-3)
.ico_tool-4
    +icon(tool-4)
.ico_tool-5
    +icon(tool-5)
.ico_tool-6
    +icon(tool-6)
.ico_tool-7
    +icon(tool-7)
.ico_tool-8
    +icon(tool-8)
.ico_tool-9
    +icon(tool-9)
.ico_trash
    +icon(trash)
.ico_type-processing-1
    +icon(type-processing-1)
.ico_type-processing-2
    +icon(type-processing-2)
.ico_type-processing-3
    +icon(type-processing-3)
.ico_type-processing-4
    +icon(type-processing-4)
.ico_type-processing-5
    +icon(type-processing-5)
.ico_upload
    +icon(upload)
.ico_user
    +icon(user)
.ico_youtube
    +icon(youtube)