.page-head__title
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: center

  [class*='ico_']
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-box-flex: 0
    -ms-flex: 0 0 30px
    -webkit-transform: rotate(0)
    -ms-transform: rotate(0)
    -webkit-transition: all 0.25s linear
    -o-transition: all 0.25s linear
    display: none
    flex: 0 0 30px
    align-items: center
    justify-content: center
    width: 30px
    height: 30px
    margin-left: 10px
    transform: rotate(0)
    border-radius: 8px
    background: rgba(91, 100, 133, 0.4)
    color: #fff
    font-size: 10px
    transition: all 0.25s linear

  &.is-active [class*='ico_']
    -webkit-transform: rotate(-180deg)
    -ms-transform: rotate(-180deg)
    -webkit-transition: all 0.25s linear
    -o-transition: all 0.25s linear
    transform: rotate(-180deg)
    transition: all 0.25s linear

@media only screen and (max-width: 1920px) and (min-width: 767px)
  .page-head__title
    font-size: calc(20px + 8 * (100vw / 1920))

@media only screen and (max-width: 1359px)
  .page-content.--production .page-content__inner
    display: block

@media only screen and (max-width: 1359px)
  .page-content.--production .page-content__sidebar
    -webkit-box-flex: 0
    -ms-flex: 0 0 100%
    -webkit-transition: all 0.35s linear
    -o-transition: all 0.35s linear
    visibility: hidden
    z-index: 10
    position: absolute
    top: 50px
    left: 0
    flex: 0 0 100%
    width: 100%
    padding: 10px
    background: #fff
    opacity: 0
    pointer-events: none
    transition: all 0.35s linear

@media only screen and (max-width: 1359px)
  .page-head__title [class*='ico_']
    display: -webkit-box
    display: -ms-flexbox
    display: flex

@media only screen and (max-width: 767px)
  .page-content.--production .table-head > * + *
    margin: 0

@media only screen and (max-width: 1199px)
  .page-content.--production .table-head
    display: block

@media only screen and (max-width: 767px)
  .page-content.--production .table-head
    margin-bottom: 20px

.page-production
  .page-main
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -ms-flex-direction: column
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    flex-direction: column

  .page-content
    -webkit-box-flex: 1
    -ms-flex-positive: 1
    flex-grow: 1

.page-content.--production
  &.is-show-menu
    .page-content__inner:after
      visibility: visible
      top: 0
      opacity: 0.6
      pointer-events: all

    .page-content__sidebar
      visibility: visible
      top: 0
      opacity: 1
      pointer-events: all

  position: relative
  padding: 60px 0

  .page-content
    position: relative

  .page-content__inner
    -webkit-box-pack: start
    -ms-flex-pack: start
    -webkit-box-align: start
    -ms-flex-align: start
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: flex-start
    justify-content: flex-start

    &::after
      -webkit-transition: all 0.35s linear
      -o-transition: all 0.35s linear
      display: block
      visibility: hidden
      z-index: 1
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: #5B6485
      mix-blend-mode: darken
      content: ''
      opacity: 0.6
      opacity: 0
      pointer-events: none
      transition: all 0.35s linear

  .page-content__sidebar
    -webkit-box-flex: 0
    -ms-flex: 0 0 240px
    display: block
    position: relative
    flex: 0 0 240px
    width: 240px

  .page-content__content
    -webkit-box-flex: 1
    -ms-flex-positive: 1
    display: block
    position: relative
    flex-grow: 1
    margin-left: 30px

  .widjet__body .footable tbody
    td
      padding: 10px 0 10px 10px

    tr:last-child
      border-bottom: 0

      td
        padding-bottom: 0

  .table-body .footable thead .footable-header th:first-child
    padding-left: 30px

    .check__box
      border: 1px solid rgba(228, 231, 239, 0.5)
      background: transparent

      &::before
        background: rgba(228, 231, 239, 0.5)

@media only screen and (max-width: 1359px)
  .page-content.--production
    padding: 30px 0

@media only screen and (max-width: 767px)
  .page-content.--production
    padding: 20px 0 60px

@media only screen and (max-width: 1359px)
  .page-content.--production .page-content__content
    margin: 0

.production-menu
  display: block
  position: relative
  max-width: 285px
  margin: 0 auto
  padding: 15px 0
  border-radius: 8px
  background: #FFFFFF

.production-menu__list
  margin: 0
  padding: 0
  list-style-type: none

  li
    display: block
    position: relative
    border-bottom: 1px solid #F8F9FA

    &:last-child
      border-bottom: 0

    &::before
      -webkit-box-shadow: 1px 0px 4px 1px rgba(34, 131, 255, 0.3)
      -webkit-transition: all 0.25s linear
      -o-transition: all 0.25s linear
      position: absolute
      top: 5px
      left: 0
      width: 4px
      height: calc( 100% - 10px)
      background: #628EFF
      box-shadow: 1px 0px 4px 1px rgba(34, 131, 255, 0.3)
      content: ''
      opacity: 0
      transition: all 0.25s linear

    &.active
      &::before
        opacity: 1

      a::before
        color: #5B6485

    a
      -webkit-box-pack: justify
      -ms-flex-pack: justify
      -webkit-box-align: center
      -ms-flex-align: center
      -webkit-box-orient: horizontal
      -webkit-box-direction: reverse
      -ms-flex-direction: row-reverse
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      position: relative
      flex-direction: row-reverse
      align-items: center
      justify-content: space-between
      height: 45px
      padding: 0 20px 0 24px
      color: #5B6485
      font-size: 14px
      font-style: normal
      font-weight: 600
      letter-spacing: 0.02em
      line-height: 100%

      +icon(chevron-right)

      &::before
        -webkit-transition: all 0.25s linear
        -o-transition: all 0.25s linear
        color: #CCD2E3
        font-size: 10px
        transition: all 0.25s linear

      &:hover::before
        color: #5B6485

.button-popap
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex
  align-items: center
  justify-content: flex-start
  padding: 0
  border: none
  background: none
  color: #5B6485
  font-size: 14px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 1.14

  span
    &.title
      -o-text-overflow: ellipsis
      max-width: 150px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap

    &:last-child
      -webkit-box-pack: center
      -ms-flex-pack: center
      -webkit-box-align: center
      -ms-flex-align: center
      -webkit-transition: all 0.25s linear
      -o-transition: all 0.25s linear
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      align-items: center
      justify-content: center
      height: 25px
      margin-left: 10px
      padding: 0 10px
      border-radius: 8px
      background: #E2EBFD
      color: #6F9BF3
      font-size: 14px
      font-style: normal
      font-weight: 600
      letter-spacing: 0.02em
      line-height: 0.8
      white-space: nowrap
      transition: all 0.25s linear

  &:hover span:last-child
    background: #CCD2E3
    color: #fff

.is-missing
  display: block
  position: relative

  &:hover .is-missing__drop, &:focus .is-missing__drop, &:active .is-missing__drop
    opacity: 1

.is-missing__title
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  color: #5B6485
  font-size: 14px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 1.14

  [class*='ico_']
    margin-right: 6px
    color: #FF6A6A
    font-size: 16px

.is-missing__drop
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: start
  -ms-flex-align: start
  -webkit-box-shadow: 0px 14px 26px -14px rgba(180, 186, 202, 0.5)
  -webkit-transition: all 0.25s linear
  -o-transition: all 0.25s linear
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  z-index: 5
  position: absolute
  top: -10px
  left: -10px
  align-items: flex-start
  justify-content: flex-start
  width: 245px
  padding: 10px
  border-radius: 0px 8px 8px 8px
  background: #FFFFFF
  box-shadow: 0px 14px 26px -14px rgba(180, 186, 202, 0.5)
  color: #5B6485
  font-size: 14px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 110%
  opacity: 0
  transition: all 0.25s linear

  [class*='ico_']
    margin-right: 6px
    color: #FF6A6A
    font-size: 16px

  h6
    margin: 0 0 4px 0
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 600
    letter-spacing: 0.02em
    line-height: 100%

.page-content__back
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: flex-start
  margin-bottom: 30px

@media only screen and (max-width: 1520px)
  .table-production-small
    .check__box
      margin-right: 0

    thead .footable-header th:first-child, tbody tr td:first-child
      padding-left: 15px !important

    thead .footable-header th:last-child, tbody tr td:last-child
      padding-right: 15px !important

    thead .footable-header th, tbody tr td
      padding: 12px 5px 12px 5px
      font-size: 12px

    thead .footable-header th .accent,
    tbody tr td .accent,
    thead .footable-header th .button-popap,
    tbody tr td .button-popap,
    thead .footable-header th .is-missing__title,
    tbody tr td .is-missing__title
      font-size: 12px

.submenu-scroll
  max-width: 100%
  overflow-x: auto

.submenu-scroll .submenu li a
  white-space: nowrap

@media only screen and (max-width: 1199px)
  .page-content.--production .table-head
    display: block

@media only screen and (max-width: 1199px)
  .submenu-scroll
    margin-bottom: 20px
    padding-bottom: 10px

@media only screen and (max-width: 1199px)
  .submenu-scroll
    margin-bottom: 20px
    padding-bottom: 10px

@media only screen and (max-width: 1199px)
  .submenu-scroll .submenu li a
    padding: 0 10px

@media only screen and (max-width: 767px)
  .submenu-scroll .submenu li a
    height: 40px
    font-size: 12px

@media only screen and (max-width: 1199px)
  .submenu-scroll .submenu li + li
    margin-left: 5px

@media only screen and (max-width: 767px)
  .submenu-scroll
    margin-bottom: 0

@media only screen and (max-width: 575px)
  .page-content.--production .table-head > div:last-child .btn
    width: auto
    margin-left: auto

.table-production tr td:first-child .btn-status
  display: none

@media only screen and (max-width: 767px)
  .table-production tr td:first-child .btn-status
    display: -webkit-inline-box
    display: -ms-inline-flexbox
    display: inline-flex
    height: 25px
    margin-top: 3px
    padding: 5px 8px

@media only screen and (max-width: 767px)
  .table-production tr th:nth-child(2),
  .table-production tr td:nth-child(2)
    display: none !important

.m-operations .modal-body, .m-material .modal-body
  max-height: 320px
  margin-right: -50px
  padding-right: 50px

.m-operations .footable tbody tr td a
  white-space: normal

.m-material
  .footable tbody tr td a
    white-space: normal

  .modal-body table tbody tr
    td a
      text-decoration: none

    &:hover td
      color: #FF6A6A

      a
        color: #FF6A6A

.production
  display: block
  position: relative

.production__box
  display: block
  position: relative
  max-width: 822px

.production__head
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: flex-start

  .nav-wrap
    display: block
    position: relative

    &::after
      display: block
      position: absolute
      top: 0
      right: 0
      width: 60px
      height: 35px
      background: -webkit-gradient(linear, right top, left top, from(#F7F8FB), color-stop(50%, rgba(247, 248, 251, 0)))
      background: -o-linear-gradient(right, #F7F8FB 0%, rgba(247, 248, 251, 0) 50%)
      background: linear-gradient(270deg, #F7F8FB 0%, rgba(247, 248, 251, 0) 50%)
      content: ''
      pointer-events: none

  .nav
    -ms-flex-wrap: nowrap
    position: relative
    flex-wrap: nowrap
    margin-left: 25px
    overflow-x: auto
    overflow-y: hidden

    .nav-link
      white-space: nowrap

      &::after
        bottom: 0

  &.--setting
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    justify-content: space-between

    .nav
      margin-right: 25px
      margin-left: 0

.production__date
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center

  [class*='ico_']
    margin-right: 8px
    color: #628EFF
    font-size: 20px

  span
    display: block
    margin-right: 8px
    padding-right: 8px
    border-right: 1px solid #E1E3EA
    color: #A3AED0
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

  b
    -webkit-box-align: center
    -ms-flex-align: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%
    cursor: pointer

    [class*='ico_']
      margin-left: 15px
      color: #A3AED0
      font-size: 10px

.production__location
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  margin-left: 60px

  [class*='ico_']
    margin-right: 8px
    color: #628EFF
    font-size: 20px

  span
    display: block
    margin-right: 8px
    padding-right: 8px
    border-right: 1px solid #E1E3EA
    color: #A3AED0
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

  b
    display: block
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

  .dropdown-toggle
    -webkit-box-orient: horizontal
    -webkit-box-direction: reverse
    -ms-flex-direction: row-reverse
    -webkit-box-align: center
    -ms-flex-align: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    flex-direction: row-reverse
    align-items: center
    border: none
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

    &::after
      display: none

    +icon(chevron-down)

    &::before
      margin-left: 5px
      color: #A3AED0
      font-size: 8px

  .dropdown-item
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

.production__body
  margin-top: 30px
  margin-bottom: 30px
  padding: 25px 20px
  background: #FFFFFF

  &.--small
    padding: 10px

.production__footer
  text-align: right

.service-date-list
  margin: 0
  padding: 0
  list-style-type: none

.service-date-list__item
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 15px
  padding-bottom: 15px
  border-bottom: 1px solid #F7F8FB

  &:last-child
    margin-bottom: 0
    padding-bottom: 0
    border-bottom: 0

.service-date-list__icon
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  flex-grow: 1
  align-items: center

  > div
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-box-flex: 0
    -ms-flex: 0 0 64px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    flex: 0 0 64px
    align-items: center
    justify-content: center
    width: 64px
    height: 64px
    border-radius: 8px
    background: #E9F0FD

  span
    display: block
    margin-left: 10px
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 1.14

.service-date-list__date-1
  span
    display: block
    min-height: 14px
    margin-bottom: 5px

  input.form-control
    width: 180px
    border: 1.5px solid #E4E7EF
    border-radius: 8px
    background: #FFFFFF

.service-date-list__date-2
  margin-left: 78px

  span
    display: block
    min-height: 14px
    margin-bottom: 5px
    color: #5B6485
    font-size: 12px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 1.17
    text-align: center

  input.form-control
    width: 180px
    border: 1.5px solid #E4E7EF
    border-radius: 8px
    background-color: #FFFFFF

.form-control-data
  background-image: url(../img/svgico/grid.svg)
  background-position: right 15px center
  background-repeat: no-repeat

.nav-tabs
  border-bottom: 2px solid #E4E7EF

  .nav-link
    display: block
    position: relative
    padding: 0 30px 15px 0
    color: #A3AED0
    font-size: 16px
    font-style: normal
    font-weight: 600
    letter-spacing: 0.02em
    line-height: 1

    &:after
      display: block
      position: absolute
      bottom: -2px
      left: 0
      width: 100%
      height: 2px
      background: #628EFF
      content: ''
      opacity: 0

    margin: 0
    border: none

  .nav-item.show .nav-link, .nav-link.active
    -webkit-box-sizing: border-box
    box-sizing: border-box
    border: none
    background-color: transparent
    color: #5B6485
    font-size: 16px
    font-style: normal
    font-weight: 600
    letter-spacing: 0.02em
    line-height: 1

  .nav-item.show .nav-link:after
    opacity: 1

  .nav-link
    &.active:after
      opacity: 1

    &:focus, &:hover
      border: none
      outline: none

.setting-list
  margin: 0
  padding: 0
  list-style-type: none

.setting-list__item
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: space-between
  padding: 15px
  border-bottom: 1px solid #F7F8FB

  &:last-child
    border-bottom: 0

.setting-list__title
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  color: #5B6485
  font-size: 14px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 1.14

.setting-list__value
  -webkit-box-pack: end
  -ms-flex-pack: end
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: flex-end
  margin-left: 20px

  input.form-control
    width: 100px

  span
    display: block
    width: 50px
    margin-left: 10px
    color: #5B6485
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 1.14

.week-list
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex
  align-items: center
  justify-content: flex-start
  margin: 0
  padding: 0
  list-style-type: none

  li
    margin-right: 2px

    label
      display: block
      position: relative
      cursor: pointer

      input[type="checkbox"]
        display: none

      span
        -webkit-box-pack: center
        -ms-flex-pack: center
        -webkit-box-align: center
        -ms-flex-align: center
        -webkit-box-flex: 0
        -ms-flex: 0 0 40px
        -webkit-transition: all 0.25s linear
        -o-transition: all 0.25s linear
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        flex: 0 0 40px
        align-items: center
        justify-content: center
        width: 40px
        height: 35px
        margin: 0
        border-radius: 8px
        background: #E4E7EF
        color: #5B6485
        font-size: 14px
        font-style: normal
        font-weight: 500
        letter-spacing: 0.02em
        line-height: 100%
        text-align: center
        transition: all 0.25s linear

        &:hover
          background: #CCD2E3
          color: #fff

      input[type="checkbox"]:checked + span
        background: #59B689
        color: #fff

.button-drag
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-box-align: center
  -ms-flex-align: center
  -webkit-box-flex: 0
  -ms-flex: 0 0 35px
  -webkit-box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  flex: 0 0 35px
  align-items: center
  justify-content: center
  width: 35px
  height: 35px
  border: none
  border-radius: 8px
  background: #FFFFFF
  box-shadow: 0px 2px 8px -1px rgba(204, 210, 227, 0.6)
  color: #CCD2E3
  font-size: 20px
  text-align: center

.page-content.--production.--turn .table-footer > div:first-child
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: flex-start

  .drop
    max-width: 254px

    + .drop
      margin-left: 30px

.submenu li a.danger
  background: #FFD2D2

  sup
    background: rgba(255, 106, 106, 0.7)

.page-content.--production.--invoice
  .table-footer > div:first-child
    -webkit-box-pack: start
    -ms-flex-pack: start
    -webkit-box-align: center
    -ms-flex-align: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: flex-start

    .drop
      max-width: 254px

      + .drop
        margin-left: 30px

  .table-footer-invoice
    display: -webkit-box !important
    display: -ms-flexbox !important
    display: flex !important

.total-materials
  -webkit-box-orient: vertical
  -webkit-box-direction: normal
  -ms-flex-direction: column
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  flex-direction: column
  margin-right: 60px

  span
    display: block
    position: relative
    color: #A3AED0
    font-size: 14px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%

  strong
    display: block
    margin-top: 9px
    color: #5B6485
    font-size: 22px
    font-style: normal
    font-weight: 700
    letter-spacing: 0.02em
    line-height: 100%

.list-info
  -webkit-box-pack: start
  -ms-flex-pack: start
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  justify-content: flex-start
  margin: 0
  padding: 0
  list-style-type: none

  li
    -webkit-box-orient: vertical
    -webkit-box-direction: normal
    -ms-flex-direction: column
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    flex-direction: column

    + li
      margin-left: 30px
      padding-left: 30px
      border-left: 1px solid #E4E7EF

    span
      display: block
      position: relative
      color: #A3AED0
      font-size: 14px
      font-style: normal
      font-weight: 500
      letter-spacing: 0.02em
      line-height: 1.14

    b
      display: block
      position: relative
      margin-top: 5px
      color: #5B6485
      font-size: 14px
      font-style: normal
      font-weight: 600
      letter-spacing: 0.02em
      line-height: 100%

.page-content.--production.--invoice
  .footable tfoot
    tr
      position: relative

      td
        &:first-child::before
          display: block
          position: absolute
          top: 0
          left: 10px
          width: calc(100% - 20px)
          height: 1px
          background: #E4E7EF
          content: ''

        padding: 15px 10px

    .accent
      margin-right: 50px
      color: #A3AED0
      font-size: 14px
      font-style: normal
      font-weight: 500
      letter-spacing: 0.02em
      line-height: 1.14

    b
      color: #5B6485
      font-size: 14px
      font-style: normal
      font-weight: 700
      letter-spacing: 0.02em
      line-height: 1.21

  .table-footer > div:first-child
    -webkit-box-pack: start
    -ms-flex-pack: start
    -webkit-box-align: center
    -ms-flex-align: center
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-items: center
    justify-content: flex-start

    .drop
      max-width: 254px

      + .drop
        margin-left: 30px

.tr-visible-modile
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  -webkit-box-align: center
  -ms-flex-align: center
  display: none
  align-items: center
  justify-content: space-between
  background-color: #fff

  > div
    padding: 15px 10px

  .accent
    color: #A3AED0
    font-size: 12px
    font-style: normal
    font-weight: 500
    line-height: 1.17

  b
    color: #5B6485
    font-size: 12px
    font-style: normal
    font-weight: 700
    letter-spacing: 0.02em
    line-height: 1.25

.table-production tr td:first-child .btn-status
  display: none

.submenu-scroll
  max-width: 100%
  overflow-x: auto

  .submenu li a
    white-space: nowrap

.page-content.--production
  &.--turn .table-head .form-control + .form-control
    margin-left: 15px

  .table-head.--invoice-4
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-direction: row
    -webkit-box-pack: justify
    -ms-flex-pack: justify
    -ms-flex-item-align: stretch
    -ms-grid-row-align: stretch
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    flex-direction: row
    align-self: stretch
    justify-content: space-between
    width: 100%

    .btn-icon
      min-width: 45px
      margin-top: 0

.service-date-list__item:last-child
  margin-bottom: 0
  padding-bottom: 0
  border-bottom: 0

@media only screen and (max-width: 1199px)
  .production__head
    display: block

@media only screen and (max-width: 767px)
  .production__location
    margin-top: 20px
    margin-left: 0

@media only screen and (max-width: 767px)
  .production__body
    margin-top: 20px
    margin-bottom: 20px
    padding: 25px 10px

@media only screen and (max-width: 767px)
  .service-date-list__item
    -ms-flex-wrap: wrap
    flex-wrap: wrap

@media only screen and (max-width: 767px)
  .service-date-list__icon
    width: 100%
    margin-bottom: 5px

@media only screen and (max-width: 767px)
  .service-date-list__icon > div
    -webkit-box-flex: 0
    -ms-flex: 0 0 50px
    flex: 0 0 50px
    width: 50px
    height: 50px

@media only screen and (max-width: 767px)
  .service-date-list__icon span
    font-size: 12px

@media only screen and (max-width: 767px)
  .service-date-list__date-1
    width: calc( 50% - 5px)

@media only screen and (max-width: 767px)
  .service-date-list__date-1 span
    font-size: 10px

@media only screen and (max-width: 767px)
  .service-date-list__date-1 input.form-control
    width: 100%
    height: 40px

@media only screen and (max-width: 767px)
  .service-date-list__date-2
    width: calc( 50% - 5px)
    margin-left: 0

@media only screen and (max-width: 767px)
  .service-date-list__date-2 span
    font-size: 10px

@media only screen and (max-width: 767px)
  .service-date-list__date-2 input.form-control
    width: 100%
    height: 40px

@media only screen and (max-width: 767px)
  .service-date-list__item
    -ms-flex-wrap: wrap
    flex-wrap: wrap

.change-city
  margin: 0
  padding: 0
  list-style-type: none

.change-city__item
  -webkit-box-align: start
  -ms-flex-align: start
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: flex-start
  margin-bottom: 15px
  padding-bottom: 15px
  border-bottom: 1px solid #F7F8FB

  &:last-child
    margin-bottom: 0
    padding-bottom: 0
    border-bottom: none

.change-city__icon
  margin-right: 15px
  color: #628EFF
  font-size: 20px

.change-city__desc
  display: block
  position: relative

.change-city__title
  margin-bottom: 15px
  color: #5B6485
  font-size: 16px
  font-style: normal
  font-weight: 500
  letter-spacing: 0.02em
  line-height: 100%

.change-city__btn
  display: block
  position: relative

@media only screen and (max-width: 1199px)
  .production__head .nav
    margin-top: 30px
    margin-left: 0

@media only screen and (max-width: 767px)
  .setting-list__item
    padding: 15px 0

@media only screen and (max-width: 767px)
  .setting-list__title
    font-size: 12px

@media only screen and (max-width: 767px)
  .setting-list__value input.form-control
    width: 70px
    height: 35px

@media only screen and (max-width: 767px)
  .production__head .nav .nav-link
    font-size: 14px

@media only screen and (max-width: 767px)
  .setting-list__value span
    width: 30px
    margin-left: 5px

.button-back
  -webkit-box-pack: center
  -ms-flex-pack: center
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex
  align-items: center
  justify-content: center
  height: 30px
  padding: 0 15px
  border-radius: 8px
  background: #E2EBFD
  color: #628EFF
  font-size: 12px
  font-style: normal
  font-weight: 600
  letter-spacing: 0.02em
  line-height: 100%

  [class*='ico_']
    margin-right: 6px

.page-content__title
  margin-left: 30px
  color: #5B6485
  font-size: 18px
  font-style: normal
  font-weight: 700
  letter-spacing: 0.02em
  line-height: 100%

.production__head.--setting
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  justify-content: space-between

@media only screen and (max-width: 1199px)
  .production__head.--setting .nav
    margin-right: 0
    margin-bottom: 20px

@media only screen and (max-width: 767px)
  .week-list li label span
    -webkit-box-flex: 0
    -ms-flex: 0 0 30px
    flex: 0 0 30px
    width: 30px
    height: 30px
    font-size: 12px