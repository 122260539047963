.page-header
  display: block
  position: relative
  padding: 19px 0
  background: #FFFFFF
  box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
  +r(1359)
    padding: 10px 0
  +r(767)
    padding: 0 0 10px
    box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
  &__inner
    display: flex
    justify-content: space-between
    align-items: center
    +r(1359)
      flex-wrap: wrap
  &__logo
    margin-right: 80px
    margin-right: vw(80px)
    +r(1560)
      margin-right: 20px
    +r(1359)
      order: 1
      margin-right: 50px
    +r(767)
      margin-right: 0
    .logo
      img
        +r(1199)
          max-width: 145px
        +r(767)
          max-width: 100px
  &__group
    display: flex
    align-items: center
    margin-right: auto
    +r(1359)
      flex-direction: column-reverse
      align-items: flex-end
      order: 2
    +r(767)
      order: -1
      width: 100%
      flex-direction: row
      justify-content: space-between
      align-items: center
      border-bottom: 1px solid #F7F8FB
      padding: 10px 0
      margin-bottom: 10px
  &__phones
    margin-right: 14px
    +r(1359)
      margin-right: 0
      margin-top: 10px
    +r(767)
      margin-top: 0
    .dropdown-toggle
      display: inline-flex
      align-items: center
      position: relative
      border: none
      background: none
      font-style: normal
      font-weight: 500
      font-size: 16px
      line-height: 100%
      letter-spacing: 0.04em
      color: #5B6485
      padding: 0 13px
      +r(767)
        padding-left: 0
      &::before
        content: ''
        display: block
        width: 0
        height: 4px
        background-color: $accent
        position: absolute
        bottom: -39px
        left: 50%
        transform: translateX(-50%)
        opacity: 0
        +tr(0.3s)
        +r(1359)
          bottom: -10px
      &.show
        &::before
          width: 100%
          opacity: 1
    .dropdown-menu
      top: 38px!important
      border: none
      background: #FFFFFF
      box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
      border-radius: 0px 0px 10px 10px
      padding: 20px
      +r(1359)
        top: 10px !important
      +r(767)
        padding: 15px
      .dropdown-item
        padding: 7px 0
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 100%
        letter-spacing: 0.04em
        color: #5B6485
        +tr(0.3s)
        +hh
          background: none
          color: $accent
  &__lang
    .dropdown-toggle
      display: inline-flex
      align-items: center
      position: relative
      border: none
      background: none
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.04em
      text-transform: uppercase
      color: #5B6485
      padding: 0 13px
      +r(1359)
        font-size: 16px
        text-transform: none
      &::before
        content: ''
        display: block
        width: 0
        height: 4px
        background-color: $accent
        position: absolute
        bottom: -39px
        left: 50%
        transform: translateX(-50%)
        opacity: 0
        +tr(0.3s)
        +r(1359)
          bottom: -35px
        +r(767)
          bottom: -10px
      &.show
        &::before
          width: 100%
          opacity: 1
    .dropdown-menu
      top: 38px!important
      border: none
      background: #FFFFFF
      box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
      border-radius: 0px 0px 10px 10px
      padding: 20px 21px
      min-width: auto
      +r(1359)
        top: 35px !important
      +r(767)
        top: 10px !important
      .dropdown-item
        padding: 5px 0
        font-style: normal
        font-weight: 500
        font-size: 18px
        line-height: 100%
        letter-spacing: 0.04em
        text-transform: uppercase
        color: #5B6485
        +tr(0.3s)
        +hh
          background: none
          color: $accent
        +r(1359)
          font-size: 16px
          text-transform: none
  &__menu
    margin-left: auto
    +r(1359)
      order: 5
      width: 100%
      margin: 10px 0 0 0
      padding-top: 10px
      border-top: 1px solid #F7F8FB
    +r(767)
      display: none
    .navbar-nav
      flex-direction: row
      .nav-item
        padding: 0 15px
        &:first-child
          padding-left: 0
      .nav-link
        display: block
        position: relative
        font-style: normal
        font-weight: 500
        font-size: 18px
        line-height: 100%
        letter-spacing: 0.02em
        color: #5B6485
        +r(1359)
          font-size: 14px
        sup
          width: 25px
          height: 25px
          display: inline-flex
          justify-content: center
          align-items: center
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          color: #5B6485
          background: #F7F8FB
          border: 1px solid #F7F8FB
          box-sizing: border-box
          border-radius: 50%
          margin-left: 6px
        span
          position: relative
          &::before
            content: ''
            display: block
            width: 0
            height: 4px
            background-color: $accent
            position: absolute
            bottom: -38px
            left: 50%
            transform: translateX(-50%)
            opacity: 0
            +tr(0.3s)
          +h
            &::before
              width: 100%
              opacity: 1
        &.active
          span
            &::before
              width: 100%
              opacity: 1

  &__user
    margin-left: 90px
    margin-left: vw(90px)
    +r(1560)
      margin-left: 40px
    +r(1359)
      order: 3
      margin-left: auto
  &__action
    display: flex
    justify-content: flex-end
    align-items: center
    +r(1359)
      order: 4
    .action-link
      margin-left: 20px
      +r(767)
        margin-left: 5px


.user
  display: block
  position: relative
  &__button
    display: flex
    align-items: center
    border: none
    background: none
    +r(767)
      padding: 0
    &:after
      display: none
    &::before
      content: ''
      display: block
      width: 0
      height: 4px
      background-color: $accent
      position: absolute
      bottom: -19px
      left: 50%
      transform: translateX(-50%)
      opacity: 0
      +tr(0.3s)
      +r(1359)
        bottom: -10px
    &.show
      &::before
        width: 100%
        opacity: 1
  &__avatar
    width: 40px
    height: 40px
    flex: 0 0 40px
    +r(767)
      width: 35px
      height: 35px
      flex: 0 0 35px
    img
      width: 100%
      height: 100%
  &__info
    display: block
    margin-left: 10px
    +r(767)
      display: none
  &__name
    font-weight: 500
    color: #5B6485
  &__label
    font-weight: 500
    font-size: 12px
    color: #A3AED0
  &__dropdown
    width: 400px
    padding: 30px
    top: 18px!important
    border: none
    background: #FFFFFF
    box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
    border-radius: 0px 0px 10px 10px
    padding: 20px
    +r(1359)
      top: 10px!important
    +r(767)
      width: 290px
      margin-right: -15px !important

.user-info
  display: block
  position: relative
  & + .user-info
    margin-top: 20px
    padding-top: 20px
    border-top: 1px solid #F7F8FB
    +r(767)
      margin-top: 10px
      padding-top: 10px
  &__title
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    margin-bottom: 10px
    +r(767)
      font-size: 16px
  &__body
    display: flex
    justify-content: space-between
    align-items: flex-start
  &__list
    list-style-type: none
    padding: 0
    margin: 0
    li
      display: flex
      justify-content: space-between
      align-items: center
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      color: #5B6485
      margin: 5px 0
      +fz(14, 12)
  &__avatar
    display: block
    position: relative
    margin: 0 0 10px 20px
    width: 80px
    height: 80px
    flex: 0 0 80px
    +r(767)
      width: 60px
      height: 60px
      flex: 0 0 60px
    img
      width: 100%
      height: 100%
  &__bottom
    display: flex
    justify-content: space-between
    align-items: flex-end
  &__link
    display: flex
    align-items: center
    a
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 17px
      letter-spacing: 0.02em
      color: #628EFF
      & + a
        margin-left: 10px
  &__exit
    a
      display: inline-block
      background: #628EFF
      box-shadow: 0px 8px 10px -10px rgba(204, 210, 227, 0.5)
      border-radius: 8px
      padding: 10px 20px
      font-style: normal
      font-weight: normal
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #FFFFFF

.action-link
  display: flex
  justify-content: center
  align-items: center
  padding: 12px
  background: #F7F8FB
  border-radius: 8px
  font-size: 16px
  color: #5B6485
  +tr(0.3s)
  +h
    color: $accent
  +r(767)
    padding: 10px
  sup
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    color: #A3AED0
    margin-left: 5px
  &.--youtube
    font-size: 20px
    padding: 10px
    +r(767)
      padding: 8px