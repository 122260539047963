.page-404-content
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 150px 0 250px
  padding: vw(150px) 0 250px
  overflow: hidden
  +r(767)
    padding: 30px 0 200px

.page-404-box
  display: block
  position: relative
  margin: 0 15px
  z-index: 1
  &::before
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: url(../img/bg_404-box.svg)
    background-position: left top
    background-repeat: no-repeat
    z-index: -1
  &:after
    content: ''
    display: block
    position: absolute
    top: calc(100% - 85px)
    right: calc(100% - 150px)
    width: 385px
    height: 305px
    background: url(../img/decor_404-bottom.svg)
    background-repeat: no-repeat
    z-index: 1
    +r(767)
      width: 280px
      background-size: contain
      top: calc(100% - 55px)
    right: calc(100% - 225px)
  &__decor
    &::before
      content: ''
      display: block
      position: absolute
      bottom: -25px
      right: calc(100% - 35px)
      width: 250px
      height: 181px
      background-image: url(../img/decor_404-right.svg)
      z-index: -1
    &::after
      content: ''
      display: block
      position: absolute
      top: 45px
      right: -35px
      width: 210px
      height: 137px
      background-image: url(../img/decor_404-left.svg)
      z-index: -1
  &__wrap
    background: linear-gradient(134.86deg, #628EFF 0.97%, #6F9BF3 98.44%)
    mix-blend-mode: multiply
    border-radius: 8px
    padding: 95px 70px 115px 70px
    text-align: center
    +r(767)
      padding: 80px 40px
  &__title
    font-style: normal
    font-weight: 900
    font-size: 64px
    line-height: 100%
    letter-spacing: 0.06em
    color: #FFFFFF
    margin-bottom: 5px
    +r(767)
      font-size: 48px
  &__subtitle
    font-style: normal
    font-weight: 500
    font-size: 18px
    line-height: 100%
    color: #FFFFFF
    margin-bottom: 10px
    +r(767)
      font-size: 13px
  &__text
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    color: #FFFFFF
    margin-bottom: 30px
    font-size: 10px
  &__btn
    .btn
      +r(767)
        font-size: 10px