.drop-menu
  display: flex
  justify-content: center
  align-items: center
  list-style-type: none
  padding: 0
  margin: 0


  +r(1359)
    display: block
    text-align: center
    padding: 0
    margin: 0
    position: absolute
    top: 0
    left: 0
    z-index: 2
    min-width: 175px
    width: 100%

  &.open
    box-shadow: 5px 10px 20px 0px rgba(#000, 0.2)
    border-radius: 8px
    overflow: hidden
    li:first-child()
      a
        border-radius: 8px 8px 0 0

  &.open li
    display: block
    &:first-child
      a::after
        transform: rotate(180deg)

  li

    +r(1359)
      margin: 0
      display: none
      position: relative
      cursor: pointer
      width: 100%

      & + li
        +r(1359)
          margin: 0

      &:first-child
        display: inline-flex
        align-items: center
        width: 100%
        border-top: 0px
        padding: 0
        background: none

        a
          width: 100%
          height: 45px
          display: flex!important
          justify-content: space-between
          align-items: center
          border-radius: 8px
          background-color: #E4E7EF
          pointer-events: none
          &::after
            content: ''
            display: block
            position: relative
            width: 15px
            height: 15px
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='Vector 25' d='M1 1L6 6L11 1' stroke='%2330393E' stroke-width='2'/%3E%3C/svg%3E%0A")
            background-position: center
            background-repeat: no-repeat
            margin-left: 10px
            +tr(0.3s)
          sup
            top: 5px
            right: 35px
          &.active
            background-color: #59B689
            color: #fff

      &:not(:first-child)
        background-color: #fff

      &:last-child
        a
          border-bottom: none

      a
        display: flex
        justify-content: flex-start
        align-items: center
        position: relative
        text-align: left
        background: #fff
        height: 45px
        line-height: 45px
        border-radius: 0
        border-bottom: 1px solid #eee
        span
          +line(1)
          padding-right: 10px
        sup
          +r(1359)
            position: absolute
            top: 3px
            right: 15px
