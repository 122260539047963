// Default variables
$font_size: 14px !default
$font_size_small: 12px !default

$input_border_radius: 8px !default
$input_height: 45px !default
$input_height_small: 30px !default
$dropdown_padding: 15px !default

$gray_dark: #444 !default
$gray: #000 !default
$gray_light: #e8e8e8 !default
$gray_lighter: #f6f6f6 !default
$primary_light: $gray !default
$arrow_color: #57CA70 !default

// Style the dropdown
.nice-select
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  background-color: #fff
  border-radius: $input_border_radius
  border: solid 1px $gray_light
  box-sizing: border-box
  clear: both
  cursor: pointer
  display: block
  font-family: inherit
  font-size: $font_size
  font-weight: normal
  color: #A3AED0
  height: $input_height
  line-height: $input_height - 2
  outline: none
  padding-left: $dropdown_padding
  padding-right: $dropdown_padding + 12
  position: relative
  text-align: left !important
  transition: all 0.2s ease-in-out
  user-select: none
  white-space: nowrap
  width: auto
  &:hover
    border-color: darken($gray_light, 5%)

  &:active, &:focus
    border-color: darken($gray_light, 5%)

  // Arrow
  &:after
    width: 6px
    height: 6px
    border-bottom: 1px solid $arrow_color
    border-right: 1px solid $arrow_color
    content: ''
    display: block
    margin-top: -4px
    pointer-events: none
    position: absolute
    right: 20px
    top: 50%
    transform-origin: 66% 66%
    transform: rotate(45deg)
    transition: all 0.15s ease-in-out

  &.open
    &:after
      transform: rotate(-135deg)

    .list
      width: calc(100% + 2px)
      margin: 0 0 0 -1px
      opacity: 1
      pointer-events: auto
      transform: scale(1) translateY(0)


  &.disabled
    border-color: lighten($gray_light, 2%)
    color: $gray
    pointer-events: none
    &:after
      border-color: lighten($arrow_color, 20%)



  // Modifiers
  &.wide
    width: 100%
    .list
      left: 0 !important
      right: 0 !important


  &.right
    float: right
    .list
      left: auto
      right: 0


  &.small
    font-size: $font_size_small
    height: $input_height_small
    line-height: $input_height_small - 2
    &:after
      height: 4px
      width: 4px

    .option
      line-height: $input_height_small - 2
      min-height: $input_height_small - 2

  .current
    line-height: 1

  // List and options
  .list
    background-color: #fff
    border-radius: $input_border_radius
    box-shadow: 0 0 0 1px rgba($gray_dark, .11)
    box-sizing: border-box
    opacity: 0
    overflow: hidden
    padding: 0
    pointer-events: none
    position: absolute
    top: 100%
    left: 0
    transform-origin: 50% 0
    transform: scale(.75) translateY(-22.5px)
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out
    z-index: 9
    &:hover .option:not(:hover)
      background-color: transparent !important


  .option
    color: #5B6485
    cursor: pointer
    font-weight: 400
    line-height: $input_height - 2
    list-style: none
    min-height: $input_height - 2
    outline: none
    padding-left: $dropdown_padding
    padding-right: $dropdown_padding + 11
    text-align: left
    transition: all 0.2s
    &:hover, &.focus, &.selected.focus
      background-color: $gray_lighter

    &.selected
      font-weight: bold

    &.disabled
      background-color: transparent
      color: $gray
      cursor: default




// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select
  .list
    display: none

  &.open
    .list
      display: block


