.table-head
  &.--page-new-order
    +r(767)
      align-items: stretch

    & > div:last-child
      display: flex
      flex-direction: row
      justify-content: flex-end
      align-items: center

      .btn
        margin-left: 10px

      .btn-icon
        max-width: 45px

      .btn-primary
        // flex-grow: 1

    & > div:last-child > *
      +r(575)
        margin-top: 0

    .submenu-box
      align-items: stretch

    .submenu-wrap
      width: 100%

.product-img-small
  display: block
  position: relative
  width: 80px
  height: 40px
  overflow: hidden
  margin-right: 10px
  img
    width: 100%
    height: 100%

  & + a
    color: #5B6485!important
    +h
      color: $accent!important
    +r(1199)
      max-width: 150px

.filters
  display: block
  position: relative

.filter-btn
  display: none
  +r(1399)
    display: block
  button
    display: flex
    justify-content: space-between
    align-items: center
    border: none
    background: #59B689
    box-shadow: 0px 8px 10px 0 rgba(89, 182, 137, 0.3)
    border-radius: 8px
    width: 290px
    height: 45px
    padding: 0 15px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF
    .ico_filter
      font-size: 24px
    .ico_chevron-down
      color: #30393E
    +r(767)
      width: 100%
  &.is-active
    button
      .ico_chevron-down
        transform: rotate(180deg)

.filter-box
  display: block
  position: relative
  background: #FFFFFF
  border-radius: 8px
  padding: 30px
  & > * + *
    margin-top: 35px

  +r(1399)
    position: absolute
    top: calc(100% + 12px)
    left: 50%
    margin-left: -145px
    box-shadow: 0px 8px 10px 0 rgba(89, 182, 137, 0.3)
    width: 290px
    z-index: 5
    +tr(0.3s)
    opacity: 0
    visibility: hidden
    pointer-events: none
    transform: translateY(50px)
  +r(767)
    width: 100%
    margin-left: -50%

  &.is-show
    opacity: 1
    visibility: visible
    pointer-events: all
    transform: translateY(0px)

  .accordion-item
    border: none

    &:last-child
      .accordion-button
        border-bottom: none

  .accordion-button
    padding: 12.5px 0
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    background: none
    border-bottom: 1px solid #F7F8FB

  .accordion-button:not(.collapsed)
    background: none

  .accordion-button:after
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='13' fill='none' viewBox='0 0 13 13'%3E%3Cpath fill='%23A3AED0' fill-rule='evenodd' d='M7.992 0h-2v5.002h-5v2h5v5.002h2V7.002h5v-2h-5V0z' clip-rule='evenodd'/%3E%3C/svg%3E%0A")
    background-position: center
    background-size: 12px

  .accordion-button:not(.collapsed)::after
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='3' fill='none' viewBox='0 0 12 3'%3E%3Cpath fill='%235B6485' d='M0 2.502v-2h12v2H0z'/%3E%3C/svg%3E%0A")

  .accordion-body
    padding: 10px 0

  .filter-list
    list-style-type: none
    padding: 0
    margin: 0

    li
      & + li
        margin-top: 5px

    label
      display: flex
      align-items: center
      cursor: pointer
      .checkbox
        margin-right: 10px

      span
        font-style: normal
        font-weight: 500
        font-size: 16px
        line-height: 100%
        letter-spacing: 0.02em
        color: #5B6485

.details-list
  display: block
  position: relative
  background-color: #fff
  margin-top: 40px
  +r(767)
    background: none
    margin-top: 15px
  &__box
    display: flex
    +r(1679)
      display: block
  &__left
    max-width: 330px
    +r(1679)
      max-width: 100%
  &__right
    flex-grow: 1
    .table-body
      .footable
        tr
          & > *:nth-child(2)
            padding-left: 15px
  &__material
    padding: 30px
    +r(1679)
      padding: 20px 20px 0
    +r(767)
      padding: 15px 10px 10px
      border-radius: 8px
      background-color: #fff
    h4
      display: block
      position: relative
      font-style: normal
      font-weight: 600
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #A3AED0
      margin-bottom: 30px
      +fz(18, 16)
      +r(767)
        margin-bottom: 15px

  &__table
    padding: 30px 0 30px 30px
    border-left: 1px solid #F7F8FB
    min-height: 100%
    +r(1679)
      padding: 0 0 30px 0
    +r(767)
      padding: 10px 0
      background-color: #fff
      border-radius: 8px 8px 0 0
      margin-top: 15px
    & > .btn
      display: flex
      align-items: center
      +ico()
        margin-right: 5px
      +r(1679)
        display: none
      +r(767)
        display: flex
        width: calc(100% - 20px)
        margin: 0 10px

    .table-body
      margin-top: 30px
      overflow: visible
      +r(767)
        margin-top: 10px

      .inputs,
      .checkboxs,
      .btns
        display: inline-flex
        justify-content: flex-start
        align-items: center
        & > div + div
          margin-left: 10px

      .inputs
        +r(767)
          flex-direction: column
          & > div + div
            margin-left: 0
            margin-top: 10px
          .form-control
            width: 100%
            max-width: 100%

      input.form-control
        display: inline-block
        max-width: 120px
        width: 120px
        height: 35px

        +r(1359)
          width: 100px

        +r(767)
          width: 100%
          max-width: 100%

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button
        -webkit-appearance: none
        margin: 0

      // .checkbox
      //   display: inline-block

      .nice-select
        display: inline-flex
        align-items: center
        width: 120px
        height: 35px
        +r(1359)
          width: 100px
        +r(767)
          width: 100%
          max-width: 100%

.material-card
  display: block
  position: relative
  +r(1679)
    display: flex
    justify-content: flex-start
    align-items: flex-start
  +r(767)
    display: block
  &__img
    img
      max-width: 100%
      margin-bottom: 15px
      +r(1679)
        margin-bottom: 0
  &__info
    +r(1679)
      margin: 0 20px
    +r(767)
      margin: 15px 0
    h3
      font-style: normal
      font-weight: 600
      font-size: 16px
      line-height: 19px
      letter-spacing: 0.02em
      color: #5B6485
      margin-bottom: 15px
    ul
      list-style-type: none
      padding: 0
      margin: 0
      li
        display: flex
        align-items: center
        font-style: normal
        font-weight: 500
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #A3AED0
        & + li
          margin-top: 10px
        span
          width: 40%
        strong
          width: 60%
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #5B6485
  &__action
    .check
      margin: 20px 0
    .btn
      white-space: nowrap
      +r(767)
        width: 100%

    .drop
      .drop-overflow
        max-height: 300px

.circle-cards
  display: block
  position: relative
  background: #FFFFFF
  border-radius: 8px
  padding: 30px
  min-height: calc(100% - 20px)
  +r(1199)
    padding: 30px 20px 20px
  +r(767)
    padding: 20px 15px 15px
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 45px
    +r(767)
      flex-direction: column
      align-items: stretch
      margin-bottom: 20px
  &__title
    font-style: normal
    font-weight: 600
    font-size: 18px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    +r(767)
      margin-bottom: 16px
  &__action
    .nice-select
      width: 300px
      margin-right: 20px
      +r(1199)
        width: 200px
      +r(767)
        width: 100%
        margin-bottom: 10px
    .input-file
      +r(767)
        width: 100%
    .btn
      +r(767)
        width: 100%
  &__body
    display: block
    position: relative
  &__info
    list-style-type: none
    padding: 0
    margin: 0
    display: flex
    justify-content: flex-start
    align-items: center
    flex-wrap: wrap
    li
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
      margin: 0 15px 15px 0
      +r(767)
        margin: 0 10px 5px 0
      &:last-child
        margin-right: 0

  &__table
    max-width: 221px
    margin-left: auto
    margin-right: auto
    +r(1199)
      margin-top: 20px
      max-width: 100%
    table
      width: 100%
      margin-bottom: 20px
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 14px
      letter-spacing: 0.02em
      color: #5B6485
      background: #F7F8FB
      border: 1px solid #5B6485
      th, td
        border: 1px solid #5B6485
        padding: 7px
      thead
        background: #5B6485
        th
          color: #fff
    ul
      list-style-type: none
      padding: 0
      margin: 0
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 14px
      letter-spacing: 0.02em
      color: #5B6485

.widjet
  &.--services-price,
  &.--quantity-goods
    height: auto
    min-height: calc(50% - 15px)
    margin-bottom: 20px
    box-sizing: border-box
    +r(1399)
      height: auto
      min-height: auto
  .scroll-box
    margin-right: -30px
    padding-right: 30px
    scrollbar-color: rgba(163, 174, 208, 0.2) #fff
    &::-webkit-scrollbar-track
      background-color: #fff
    &::-webkit-scrollbar-thumb
      background-color: rgba(163, 174, 208, 0.2)

.widjet
  &.--services-price
    background-image: url(../img/bg_widjet-services-price.svg)
    background-repeat: no-repeat
    background-position: left bottom
    +r(1399)
      background-image: none
      margin-top: 20px
    .widjet__body
      padding-left: 100px
      +r(1399)
        padding-left: 0
    .widjet__action
      .btn-icon
        font-size: 20px
        color: #CCD2E3
    .scroll-box
      max-height: 155px
      overflow: auto
      +r(1399)
        max-height: none
        overflow: visible
    .table
      tr
        td:nth-child(1)
          font-weight: 700
          color: #5B6485
        td:nth-child(3)
          font-weight: 500
          color: #A3AED0

.widjet
  &.--quantity-goods
    .scroll-box
      max-height: 180px
      overflow: auto
      +r(1399)
        max-height: none
        overflow: visible



.dropdown-edge
  &__tooltip
    display: none
    position: absolute
    top: calc(100% + 5px)
    left: 0
    background-color: #fff
    padding: 15px 20px
    z-index: 10
    border: 3px solid currentColor
    color: #5B6485
    border-radius: 0 8px 8px 8px
    box-shadow: 0px 5px 15px 0 rgba(163, 174, 208, 0.3), 0px 0px 20px 0 rgba(163, 174, 208, 0.3)
    span
      color: #000
      white-space: nowrap
  +h
    .dropdown-edge__tooltip.is-active
      display: block
      +r(1199)
        display: none

.check-edge
  display: inline-block
  position: relative
  width: 35px
  height: 35px
  flex: 0 0 35px
  background: #fff
  box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
  border-radius: 8px
  border: none
  cursor: pointer
  &::after
    display: none
  &::before
    content: ''
    display: block
    position: absolute
    pointer-events: none
    background: #CCD2E3
  &.is-checked
    &::before
      background: currentColor
  &.--top
    &:before
      width: 35px
      height: 8px
      left: 0
      top: 0
      border-radius: 8px 8px 0 0
  &.--left
    &:before
      width: 8px
      height: 35px
      right: 0
      top: 0
      border-radius: 0 8px 8px 0
  &.--bottom
    &:before
      width: 35px
      height: 8px
      left: 0
      bottom: 0
      border-radius: 0 0 8px 8px
  &.--right
    &:before
      width: 8px
      height: 35px
      left: 0
      top: 0
      border-radius: 8px 0 0 8px

.slider-circle-cards
  display: block
  position: relative

  .slider-nav-box
    +r(1199)
      display: flex
      justify-content: center

  .slider-nav
    position: absolute
    left: calc(100% + 24px)
    bottom: 0
    z-index: 10
    +r(1199)
      position: relative
      left: auto
      bottom: auto
      margin: 20px auto 0

.slider-nav
  display: inline-flex
  align-items: center
  width: 140px
  height: 35px
  background: #FFFFFF
  border: 1.5px solid #E4E7EF
  box-sizing: border-box
  border-radius: 8px
  color: #A3AED0
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
.slider-nav-prev
  display: flex
  justify-content: center
  align-items: center
  width: 35px
  height: 35px
  flex: 0 0 35px
.slider-nav-pagination
  width: 70px
  text-align: center
  color: #CCD2E3
  .swiper-pagination-current
    color: #A3AED0
    font-weight: 500
.slider-nav-next
  display: flex
  justify-content: center
  align-items: center
  width: 35px
  height: 35px
  flex: 0 0 35px


.table-body-overflow
  overflow-y: scroll
  overflow-x: none
  max-height: 200px

  scrollbar-color: $accent #E2EBEC
  scrollbar-width: thin!important
  -moz-appearance: none !important

  &::-webkit-scrollbar
    width: 4px

  &::-webkit-scrollbar-track
    background-color: #E2EBEC

  &::-webkit-scrollbar-thumb
    background-color: $accent