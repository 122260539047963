.page-footer
  display: block
  position: relative
  background: #5B6485
  box-shadow: 0px 4px 31px rgba(163, 174, 208, 0.2)
  padding: 38px 0

  +r(1359)
    padding: 30px 0 15px

  &__inner
    display: flex
    justify-content: space-between
    align-items: stretch

  &__left
    +r(1199)
      margin-right: 20px
    +r(767)
      margin: 0

  &__logo
    display: block
    position: relative
    margin-bottom: 15px
    .logo
      img
        +r(1199)
          max-width: 145px

  &__dev
    display: flex
    align-items: center
    span
      display: inline-block
      font-style: normal
      font-weight: 500
      font-size: 12px
      line-height: 100%
      letter-spacing: 0.02em
      color: $base2
      margin-right: 10px

  &__center
    flex-grow: 1
    margin: 0 70px
    margin: 0 vw(70px)

    +r(1359)
      margin: 0 20px

  &__center-top
    display: flex
    justify-content: space-between
    align-items: center

    +r(767)
      flex-direction: column

    & > * + *
      margin-left: 20px
      +r(767)
        margin: 20px 0 0 0

  &__group
    display: flex
    justify-content: space-between
    align-items: center
    flex-grow: 1

    +r(1023)
      flex-direction: column
      align-items: flex-start
      +r(767)
        align-items: center
        margin-bottom: 20px

    & + .page-footer__group
      +r(1023)
        align-items: flex-end

  &__menu
    & + .page-footer__menu
      +r(1023)
        margin-top: 5px
    ul
      list-style-type: none
      padding: 0
      margin: 0
      +r(767)
        text-align: center
      li
        & + li
          margin-top: 10px
          +r(1023)
            margin-top: 5px
        a
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #F7F8FB
          +tr(0.3s)
          +h
            color: $base2

  &__download
    margin-left: 75px
    margin-left: vw(75px)
    +r(1023)
      margin-left: 0
    a
      display: inline-flex
      justify-content: center
      align-items: center
      border: 1.5px solid $base2
      box-sizing: border-box
      border-radius: 8px
      padding: 10px 9px
      white-space: nowrap


      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #FFFFFF
      +tr(0.3s)

      +ico()
        color: $base2
        font-size: 15px
        margin-right: 5px

      +h
        background-color: $base2
        color: $base
        +ico()
          color: $base

  &__social
    +r(1023)
      margin-top: 10px
      margin-right: 30px

  &__center-bottom
    border-top: 1px solid rgba(163, 174, 208, 0.5)
    padding-top: 15px
    margin-top: 20px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: $base2
    +r(1199)
      text-align: center

  &__right
    display: block
    position: relative

    +r(1560)
      max-width: 220px
    +r(1359)
      display: none

  &__contacts
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF

    p
      margin-bottom: 10px

    .accent
      color: $base2
      font-size: 12px

    a
      display: inline-block
      position: relative
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #FFFFFF
      +tr(0.3s)
      +h
        color: $base2

      & + a
        margin-left: 20px
        +r(1560)
          margin-left: 0
          margin-top: 10px

.btns-change-user
  display: flex
  flex-direction: column
  position: fixed
  left: 10px
  bottom: 70px
  z-index: 9
  +r(767)
    bottom: 60px

  .btn
    width: 50px
    height: 50px
    padding: 5px
    +r(767)
      width: 40px
      height: 40px
    & + .btn
      margin-top: 10px

.page-footer.--production
  padding: 15px 0
  .page-footer__inner
    align-items: center
  .copy
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
