/* Check */

.check
  display: inline-block
  position: relative
  padding-left: 25px
  line-height: 25px
  cursor: pointer

.check__input
  position: absolute
  width: 1px
  height: 1px
  overflow: hidden
  clip: rect(0 0 0 0)
  +hh
    box-shadow: none

.check__box
  display: inline-flex
  justify-content: center
  align-items: center
  position: relative
  margin-left: -25px
  margin-right: 10px
  width: 25px
  height: 25px
  flex: 0 0 25px
  overflow: hidden
  border-radius: 8px
  background-color: white
  background-repeat: no-repeat
  background-position: 50% 50%
  border: 1px solid #E4E7EF
  box-sizing: border-box

  &::before
    content: ''
    display: block
    width: 8px
    height: 8px
    background: #5B6485
    opacity: 0
    +tr(0.2s)


/* Checked */

.check__input:checked + .check__box:before
  opacity: 1

/* Focused */

.check__input:focus + .check__box
  border: 1.5px solid #CCD2E3

/* Disabled */

.check__input:disabled + .check__box
  opacity: 0.5

.check__input:checked:disabled + .check__box
  opacity: 0.5