.upload-unit
  display: flex
  align-items: center
  position: relative
  width: 100%
  &__label
    display: flex
    align-items: center
    padding-top: 10px
    padding-bottom: 10px
    cursor: pointer
    width: 100%
    +h
      .upload-unit__icon-down
        background-color: #628EFF
        color: #fff
      .upload-unit__status
        +icon(file-import)
        color: #fff
        background-color: $accent
      .upload-unit__status.success
        background: #73CD9B
        color: #fff
        font-size: 20px
        +icon(file-success)
      .upload-unit__status.cancel
        background: #fff
        color: #FA8A96
        +icon(file-cancel)

  &__input
    position: absolute
    visibility: hidden
    opacity: 0
    pointer-events: none
  &__icon
    display: inline-block
    position: relative
  &__icon-file
    display: flex
    justify-content: center
    align-items: center
    width: 55px
    height: 55px
    background: rgba(204, 210, 227, 0.5)
    border-radius: 8px
    font-size: 30px
    color: #fff
    [class*='ico_']
      text-align: center
  &__status
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    background: #FFFFFF
    border-radius: 8px
    box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
    position: absolute
    right: -9px
    bottom: -9px
    z-index: 2
    color: $accent
    +tr(0.3s)
    +icon(file-import)
    &::before
      font-size: 20px

    &.success
      background: #73CD9B
      color: #fff
      font-size: 20px
      +icon(file-success)
    &.cancel
      background: #fff
      color: #FA8A96
      +icon(file-cancel)
  &__trash
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    background: #FFFFFF
    border-radius: 8px
    box-shadow: 0px 2px 8px 0 rgba(204, 210, 227, 0.6)
    position: absolute
    right: -9px
    bottom: -9px
    z-index: 2
    color: #FF6A6A
    cursor: pointer
    +tr(0.3s)
    +h
      background-color: #FF6A6A
      color: #fff
  &__info
    display: block
    position: relative
    padding-left: 20px
    flex-grow: 1
  &__title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 110%
    letter-spacing: 0.02em
    color: #5B6485
    +line(1)
  &__size
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    margin-top: 6px
    &.cancel
      color: red

.upload-unit.is-disabled
  .upload-unit__label
    cursor: no-drop
  .upload-unit__status
    background: #fff
    color: #FA8A96
    +icon(file-cancel)
  .upload-unit__input
    pointer-events: none


.upload-file-block
  display: block
  position: relative
  &__box
    display: block
    position: relative
    .upload-unit
      margin-bottom: 30px
      padding-bottom: 10px
  &__btn
    display: block
    position: relative
  &__label
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
  &__input
    display: none
  &__label-content
    display: flex
    justify-content: center
    align-items: center
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485
    +ico()
      display: flex
      justify-content: center
      align-items: center
      width: 35px
      height: 35px
      background: #628EFF
      border-radius: 8px
      color: #fff
      font-size: 20px
      margin-right: 15px