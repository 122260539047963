.checkbox-custome
  --background: #fff
  --border: #000000
  --border-hover: #bbc1e1
  --border-active: #1e2235
  --tick: #fff
  position: relative
  margin: 0

  label
    display: flex
    cursor: pointer


  input[type=checkbox]:focus,
  input[type=file]:focus,
  input[type=radio]:focus
    outline: none

  input, svg
    width: 21px
    height: 21px
    display: block
    outline: none

  input
    -webkit-appearance: none
    -moz-appearance: none
    position: relative
    outline: none
    background: var(--background)
    border: none
    margin: 0 10px 0 0
    padding: 0
    cursor: pointer
    border-radius: 4px
    -webkit-transition: box-shadow 0.3s
    transition: box-shadow 0.3s
    box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border))

    &:hover
      --s: 1px
      --b: var(--border-hover)

    &:checked
      --b: var(--border-active)

  svg
    pointer-events: none
    fill: none
    stroke-width: 1px
    stroke-linecap: round
    stroke-linejoin: round
    stroke: $accent
    position: absolute
    top: 0
    left: 0
    width: 21px
    height: 21px
    -webkit-transform: scale(var(--scale, 1)) translateZ(0)
    transform: scale(var(--scale, 1)) translateZ(0)

  span
    display: inline-block
    vertical-align: middle
    color: black
    font-family: Lato
    font-size: 18px
    +fz(18, 14)
    font-weight: 400
    line-height: normal

  &.path
    input:checked
      --s: 2px
      -webkit-transition-delay: 0.4s
      transition-delay: 0.4s

      + svg
        --a: 16.1 86.12
        --o: 102.22

    svg
      stroke-dasharray: var(--a, 86.12)
      stroke-dashoffset: var(--o, 86.12)
      -webkit-transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s
      transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s


/* Rating

.rating
  margin: 0 0 1em 0
  padding: 0
  border: none

  &__caption
    margin-bottom: 0.5em
    padding: 0

  &__group
    position: relative
    width: 8.3em
    height: 1.7em
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%23E4E7EF' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")
    background-size: 1.7em
    background-repeat: repeat-x

  &__input
    position: absolute
    width: 1px
    height: 1px
    overflow: hidden
    clip: rect(0 0 0 0)

    &:focus ~ .rating__focus
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: -1
      outline: 0.2em solid #4a90e2
      outline-offset: 0.2em

  &__star
    position: absolute
    top: 0
    left: 0
    margin: 0
    height: 1.7em
    background-size: 1.7em
    background-repeat: repeat-x

    &:hover
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%23FBBC05' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

  &__input:checked + .rating__star
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%23FBBC05' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

  &__star
    &:hover ~ .rating__star
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 19'%3E%3Cpath fill='%23E4E7EF' d='M10 0l2.36 7.28L20 7.25l-6.19 4.47L16.19 19 10 14.48 3.83 19l2.36-7.28L0 7.25l7.66.03z'/%3E%3C/svg%3E%0A")

    &:nth-of-type(1)
      z-index: 5
      width: 1.7em

    &:nth-of-type(2)
      z-index: 4
      width: 3.4em

    &:nth-of-type(3)
      z-index: 3
      width: 5.1em

    &:nth-of-type(4)
      z-index: 2
      width: 6.8em

    &:nth-of-type(5)
      z-index: 1
      width: 8.3em

.social
  display: flex
  justify-content: flex-start
  align-items: center
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: block
    position: relative
    & + .social__item
      margin-left: 5px
  &__link
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    color: #A3AED0
    width: 35px
    height: 35px
    font-size: 18px
    +tr(0.3s)
    +h
      color: #fff
    &:active
      color: #fff

.table-nav
  -webkit-box-pack: end
  -ms-flex-pack: end
  -webkit-box-align: center
  -ms-flex-align: center
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  position: relative
  align-items: center
  justify-content: flex-end

  +r(767)
    justify-content: center

  .drop
    min-width: 140px
    margin-right: 20px
    +r(767)
      min-width: 140px
      margin-right: 10px
    .drop-button
      padding: 6.5px 15px
      height: 35px

  .footable-pagination-wrapper
    -webkit-box-pack: center
    -ms-flex-pack: center
    -webkit-box-align: center
    -ms-flex-align: center
    -webkit-box-sizing: border-box
    box-sizing: border-box
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    position: relative
    align-items: center
    justify-content: center
    width: 140px
    height: 35px
    border: 1.5px solid #E1E3EA
    border-radius: 8px
    background: #FFFFFF
    +tr(0.3s)
    +hh
      border: 1.5px solid #A3AED0
    +r(767)
      width: 140px

    .divider, .footable-page-nav, .footable-page
      display: none !important

    .footable-page-nav
      &[data-page="prev"], &[data-page="next"]
        -webkit-box-pack: center
        -ms-flex-pack: center
        -webkit-box-align: center
        -ms-flex-align: center
        display: -webkit-box !important
        display: -ms-flexbox !important
        display: flex !important
        position: absolute
        top: 0
        align-items: center
        justify-content: center
        width: 32px
        height: 32px

      &[data-page="prev"] .footable-page-link, &[data-page="next"] .footable-page-link
        -webkit-box-pack: center
        -ms-flex-pack: center
        -webkit-box-align: center
        -ms-flex-align: center
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        align-items: center
        justify-content: center
        width: 32px
        height: 32px
        color: #C3C8D5
        font-size: 24px
        text-decoration: none
        +hh
          color: #5B6485

      &[data-page="prev"]
        left: 0

      &[data-page="next"]
        right: 0

    .label
      margin: 0
      padding: 0
      color: #878C98
      font-size: 14px
      font-style: normal
      font-weight: 500
      letter-spacing: 0.02em
      line-height: 100%

.page-nav-box
  display: flex
  justify-content: flex-end
  margin: 30px 0 0 0
  .drop
    width: 120px
    margin: 0 20px 0 0
    .form-control
      height: 35px

.page-nav
  display: flex
  justify-content: space-between
  align-items: center
  width: 140px
  height: 35px
  background: #FFFFFF
  border: 1.5px solid #E4E7EF
  box-sizing: border-box
  border-radius: 8px
  &__arrow
    width: 35px
    height: 35px
    display: flex
    justify-content: center
    align-items: center
    color: #CCD2E3
    font-size: 10px
    cursor: pointer
  &__numbers
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #CCD2E3
  &__current
    color: #A3AED0

.angles
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  & > *
    margin: 0 10px 10px 0
    &:last-child
      margin-right: 0

.angle
  &__label
    width: 35px
    height: 35px
    background: #FFFFFF
    border-radius: 8px
    box-shadow: 0px 2px 8px 0px rgba(204, 210, 227, 0.6)
    cursor: pointer
    display: block
    position: relative
  &__checkbox
    position: absolute
    height: 0
    opacity: 0
    pointer-events: none
  &__value
    display: block
    position: absolute
    width: 18px
    height: 18px
    border-top: 8px solid #CCD2E3
    border-left: 8px solid #CCD2E3
    border-radius: 8px 0 0 0

  &__label
    input[type="checkbox"],
    input[type="radio"]
      &:checked
        & + span
          border-color: $accent

  &.angle--top-left
    .angle__value
      top: 0
      left: 0
      transform: rotate(0)
  &.angle--top-right
    .angle__value
      top: 0
      right: 0
      transform: rotate(90deg)

  &.angle--bottom-left
    .angle__value
      bottom: 0
      left: 0
      transform: rotate(-90deg)

  &.angle--bottom-right
    .angle__value
      bottom: 0
      right: 0
      transform: rotate(180deg)


.sides
  display: flex
  justify-content: flex-start
  align-items: center
  flex-wrap: wrap
  & > *
    margin: 0 10px 10px 0
    &:last-child
      margin-right: 0

.side
  &__box
    display: flex
    align-items: center
    cursor: pointer
  &__label
    width: 35px
    height: 35px
    background: #FFFFFF
    border-radius: 8px
    box-shadow: 0px 2px 8px 0px rgba(204, 210, 227, 0.6)
    cursor: pointer
    display: block
    position: relative
    overflow: hidden
  &__checkbox
    position: absolute
    height: 0
    opacity: 0
    pointer-events: none
  &__value
    display: block
    position: absolute
    background-color:#CCD2E3
  &__label
    input[type="checkbox"],
    input[type="radio"]
      &:checked
        & + span
          background-color: $accent
  &__title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
    margin-left: 10px


  &.side--top
    .side__value
      width: 35px
      height: 8px
      top: 0
      left: 0

  &.side--right
    .side__value
      width: 8px
      height: 35px
      top: 0
      right: 0

  &.side--bottom
    .side__value
      width: 35px
      height: 8px
      bottom: 0
      left: 0

  &.side--left
    .side__value
      width: 8px
      height: 35px
      top: 0
      left: 0

  &.side--all
    .side__value
      width: 35px
      height: 35px
      top: 0
      left: 0
      &:after
        content: ''
        display: block
        width: 19px
        height: 19px
        background-color: #fff
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

.action-group.--side
  .action-group-drop
    left: -9px
    right: auto
  .action-group-list
    flex-direction: row-reverse
  .action-group-list li:last-child
    margin-right: 13px