+font("GothamPro" , "GothamPro"     , 400, normal)
+font("Gilroy" , "Gilroy-UltraLight", 100, normal)
+font("Gilroy" , "Gilroy-Thin"      , 200, normal)
+font("Gilroy" , "Gilroy-Light"     , 300, normal)
+font("Gilroy" , "Gilroy-Regular"   , 400, normal)
+font("Gilroy" , "Gilroy-Medium"    , 500, normal)
+font("Gilroy" , "Gilroy-SemiBold"  , 600, normal)
+font("Gilroy" , "Gilroy-Bold"      , 700, normal)
+font("Gilroy" , "Gilroy-Heavy"     , 800, normal)
+font("Gilroy" , "Gilroy-Black"     , 800, normal)
+font("Gilroy" , "Gilroy-ExtraBold" , 900, normal)


