.g-4, .gx-4
  --bs-gutter-x: 30px

.g-4, .gy-4
  --bs-gutter-y: 30px

.btn-close
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23CCD2E3'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat

.btn-close:hover
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235B6485'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat

.form-select
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")

.form-check-input:checked[type=checkbox]
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")

.form-check-input[type=checkbox]:indeterminate
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")

.form-check-input:checked[type=radio]
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")

.form-switch .form-check-input:checked
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")

.form-switch .form-check-input
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e")

.form-switch .form-check-input:focus
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e")

.form-control.is-valid,
.was-validated .form-control:valid
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")

.form-control.is-invalid,
.was-validated .form-control:invalid
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"]
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")

.navbar-light .navbar-toggler-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")

.navbar-dark .navbar-toggler-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")

.accordion-button::after
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
  width: 12px
  height: 12px

.accordion-button:not(.collapsed)::after
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")

.carousel-control-prev-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")

.carousel-control-next-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")

.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid
  background: none

.container-xxl
  max-width: 1700px

.container-lg
  max-width: 1170px

.container-sm
  max-width: 850px

.form-group
  margin-bottom: 20px
  position: relative
  +r(767)
    margin-bottom: 10px

.btn
  display: inline-flex
  justify-content: center
  align-items: center
  padding: 14.5px 20px
  font-family: 'GothamPro'
  font-style: normal
  font-weight: normal
  font-size: 14px
  line-height: 100%
  text-align: center
  letter-spacing: 0.02em
  border-radius: 8px
  +hh
    box-shadow: none
  &:active:focus
    box-shadow: none

.btn-primary
  color: #FFFFFF
  background-color: #5A7AFA
  border-color: #5A7AFA
  box-shadow: 0px 8px 10px 0 rgba(189, 207, 244, 0.5)
  &:hover,
  &:focus
    background-color: darken(#5A7AFA, 10%)
    border-color: darken(#5A7AFA, 10%)
  &:active
    background-color: darken(#5A7AFA, 10%)
    border-color: darken(#5A7AFA, 10%)
    box-shadow: none

.btn-secondary
  color: #fff
  background-color: #A3AED0
  border-color: #A3AED0
  box-shadow: 0px 8px 10px 0 rgba(189, 207, 244, 0.5)
  &:hover,
  &:focus
    background-color: #707997
    border-color: #707997
  &:active
    background-color: #707997
    border-color: #707997
    box-shadow: none

.btn-light
  background: #FFFFFF
  box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
  border-radius: 8px

  &:hover,
  &:focus
    background-color: #fff
    box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)

  &.active
    color: #fff
    background: #A3AED0

  &[aria-expanded="true"]
    color: #fff
    background: #A3AED0

.btn-info
  background: #E4E7EF
  border-color: #E4E7EF
  border-radius: 8px

  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  text-align: center
  letter-spacing: 0.02em
  color: #5B6485

  +h
    background-color: $accent
    color: #fff

.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.show>.btn-light.dropdown-toggle
  color: #fff
  background-color: #A3AED0
  border-color: #A3AED0

.btn-check:focus+.btn-light
  box-shadow: none

.btn-link
  text-decoration: none
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #5B6485

.form-control,
.search-control
  height: 45px
  background: #FFFFFF
  border: 1.5px solid #E4E7EF
  box-sizing: border-box
  border-radius: 8px

  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 16px
  letter-spacing: 0.02em
  color: #5B6485

  +placeholder()
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0

  &:hover
    border: 1.5px solid #CCD2E3

  &:focus
    border: 1.5px solid #A3AED0
    box-shadow: none!important
    +placeholder()
      opacity: 0


.custom-control
  padding-left: 0
  position: relative

.custom-control-input
  display: none
  opacity: 0
  pointer-events: none

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label:before
  border-color: #E4E7EF

.custom-control-input:not(:disabled):active ~ .custom-control-label::before
  border-color: #E4E7EF
  background-color: transparent!important

.custom-control-label
  display: inline-block
  align-items: baseline
  padding-left: 35px
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #5A5D65
  cursor: pointer
  a
    font-style: normals
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5A5D65
    text-decoration: none!important
    +tr(0.3s)
    +h
      color: $accent

.custom-control-label::before
  position: absolute
  top: -3px
  left: 0
  display: block
  width: 25px
  height: 25px
  pointer-events: none
  content: ""
  border: 1px solid #E4E7EF
  background-color: #fff
  box-sizing: border-box
  border-radius: 8px

.custom-control-label::after
  position: absolute
  top: 5px
  left: 8.5px
  display: block
  width: 8px
  height: 8px
  content: ""
  background-color: #5B6485
  opacity: 0
  border-radius: 0px

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after
  opacity: 1

.custom-control-input:focus ~ .custom-control-label::before
  box-shadow: none

.custom-control-input:not(:disabled):active ~ .custom-control-label::before
  color: #fff
  background-color: #DFDFDF
  border-color: #DFDFDF

.form-check-label
  cursor: pointer

.row-small
  margin-left: -5px
  margin-right: -5px
  & > *
    padding-left: 5px
    padding-right: 5px

.custom-checkbox
  min-height: 25px

.form-label
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 100%
  letter-spacing: 0.02em
  color: #5B6485
  span
    color: #A3AED0

.accordion-button
  +hh
    box-shadow: none