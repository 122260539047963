.partner-item
  display: block
  position: relative
  padding: 20px 30px 20px
  background: #FFFFFF
  border-radius: 8px
  transform: scale(1)
  +tr(0.3s)
  +h
    transform: scale(1.05)
    box-shadow: 0px 2px 20px rgba(204, 210, 227, 0.6)
    .partner-item__logo
      filter: grayscale(0)
  &__box
    display: block
    position: relative
    text-align: center
  &__logo
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    margin-bottom: 20px
    padding-bottom: 20px
    border-bottom: 1px solid #F7F8FB
    filter: grayscale(1)
    +tr(0.3s)
    img
      max-height: 90px
  &__title
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 19px
    letter-spacing: 0.02em
    color: #5B6485
  &__rating
    .rating
      margin: 5px 0 0 0
      font-size: 10px
    .rating__group
      margin: 0 auto
  &__btn
    margin: 20px 0 15px
    .btn
      padding: 10px 19.5px
      background: #628EFF
      box-shadow: 0px 8px 10px rgba(189, 207, 244, 0.5)
      border-radius: 8px
  &__show-numb
    min-height: 30px
    a
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #628EFF

    .show-numb-box
      display: none
      flex-direction: column
      text-align: center
      &.is-active
        display: flex
      a.phone
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #5B6485
      a.link
        font-style: normal
        font-weight: 600
        font-size: 14px
        line-height: 100%
        letter-spacing: 0.02em
        color: #628EFF
        margin-top: 2px


.choosing-headers-list
  list-style-type: none
  padding: 0
  margin: 0 0 30px 0
  li
    display: flex
    flex-direction: column
    span
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 16px
      letter-spacing: 0.02em
      color: #A3AED0
      margin-bottom: 4px
    strong
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485

    & + li
      margin-top: 10px