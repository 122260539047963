.widjet.--bonus
  &::before
    content: ''
    display: block
    position: absolute
    right: 0
    bottom: 0
    width: 165px
    height: 150px
    background-image: url(../img/decor_widjet-bonus.svg)
    z-index: 1
    +r(767)
      right: -20px

.bonus-card
  display: block
  position: relative
  padding: 13px 17px
  background: #FFFFFF
  border: 4px solid #F7F8FB
  box-sizing: border-box
  border-radius: 8px
  width: 100%
  max-width: 250px
  & + .bonus-card
    margin-top: 10px
  &__title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
  &__value
    font-style: normal
    font-weight: 600
    font-size: 24px
    line-height: 29px
    letter-spacing: 0.02em
    color: #5B6485
    margin-top: 3px
  &.--decor
    border: none
    background: url(../img/bg_widjet-bonus.svg), linear-gradient(92.25deg, #7A61B8 0%, #6F9BF3 101.72%)
    background-position: left top 5px
    box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12)
    border-radius: 8px
    .bonus-card__title,
    .bonus-card__value
      color: #fff

.bonus-tooltip
  display: block
  position: relative

.bonus-tooltip-btn
  display: flex
  justify-content: center
  align-items: center
  margin-left: 10px
  background-color: transparent
  padding: 0
  border: 0
  width: 20px
  height: 20px
  color: #DADADA
  font-size: 18px
  position: relative
  z-index: 5

.bonus-tooltip-drop
  display: block
  position: absolute
  top: -20px
  left: -5px
  z-index: 4
  background: #FFFFFF
  box-shadow: 0px 5px 15px -1px rgba(163, 174, 208, 0.3)
  border-radius: 0px 8px 8px 8px
  padding: 20px 20px 20px 50px
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 140%
  color: #5B6485
  width: 370px
  opacity: 0
  pointer-events: none
  visibility: hidden
  +r(767)
    width: 280px
    font-size: 12px
  +r(413)
    width: 300px
    padding: 50px 20px 20px 20px
    left: -100px
  p
    margin: 0

.bonus-tooltip
  +h
    .bonus-tooltip-btn
      color: $accent
    .bonus-tooltip-drop
      opacity: 1
      pointer-events: all
      visibility: visible