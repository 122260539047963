.widjet.--new-user
  background-image: url(../img/bg_widjet-new-user.svg)
  background-repeat: no-repeat
  background-position: right bottom
  +r(1199)
    background-image: none

.widjet-new-user-list
  list-style-type: none
  padding: 0
  margin: 0
  &__item
    display: flex
    justify-content: space-between
    align-items: center
    & + .widjet-new-user-list__item
      margin-top: 20px

    &:nth-child(3n + 1)
      .widjet-new-user-list__info:before
        background: #73CD9B

    &:nth-child(3n + 2)
      .widjet-new-user-list__info:before
        background: #796BC2

    &:nth-child(3n + 3)
      .widjet-new-user-list__info:before
        background: #628EFF
  &__info
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    flex-grow: 1
    position: relative
    padding-left: 24px
    padding-right: 10px
    +r(767)
      padding-left: 15px
    &::before
      content: ''
      display: block
      position: absolute
      top: 0
      left: 0
      width: 4px
      height: 100%
      box-shadow: 1px 0px 4px 1px rgba(115, 205, 155, 0.3)
  &__company
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
    +r(767)
      font-size: 12px
  &__user
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
    +r(767)
      font-size: 12px
  &__phone
    display: block
    position: relative
    margin: 0 5px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
    +r(767)
      font-size: 12px