
.drop
  display: block
  position: relative
  min-width: 100px
  z-index: 3
  &._active
    z-index: 11
    .drop-button
      background-image: none
    .form-control
      border-radius: 8px 8px 0 0
      border: 1.5px solid #A3AED0
  &._active-close
    .drop-button
      background-image: none

  &-box
    display: none
    position: absolute
    top: 100%
    left: 0
    box-sizing: border-box
    background-color: #fff
    padding: 0 0 5px 0
    border: 1.5px solid #A3AED0
    border-top: 0
    border-radius: 0 0 8px 8px
    z-index: 3
    width: 100%

  &-input
    height: 45px
    line-height: 45px
    padding: 0 45px 0 15px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    +hh
      box-shadow: none
    &:focus
      +placeholder()
        opacity: 0

  &-input-hide
    display: none

  &-button
    text-align: left
    color: #7A7A7A
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23A3AED0' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E")
    background-repeat: no-repeat
    background-position: right 18px center
    background-size: 10px
    padding: 11.5px 15px
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

  &-clear
    display: none
    justify-content: center
    align-items: center
    position: absolute
    color: $accent
    top: 0
    right: 0
    width: 45px
    height: 45px
    border-radius: 100%
    cursor: pointer
    z-index: 5
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='none' stroke='%23A3AED0' stroke-width='1.06' d='M16,16 L4,4'%3E%3C/path%3E%3Cpath fill='none' stroke='%23A3AED0' stroke-width='1.06' d='M16,4 L4,16'%3E%3C/path%3E%3C/svg%3E")
    background-position: center
    background-repeat: no-repeat
    background-size: 15px
    &._active
      display: flex
      & + .form-control
        background-image: none!important

  &-overflow
    max-height: 90px
    overflow: auto
    scrollbar-color: #E2EBEC #FFFFFF
    scrollbar-width: thin!important
    -moz-appearance: none !important

    &::-webkit-scrollbar
      width: 4px

    &::-webkit-scrollbar-track
      background-color: #FFFFFF

    &::-webkit-scrollbar-thumb
      background-color: #E2EBEC

  .drop-list
    list-style-type: none
    padding: 0
    margin: 0

  .drop-list-item
    display: block
    position: relative
    padding: 5px 15px
    font-size: 14px
    display: block
    position: relative
    color: #000
    cursor: pointer
    border-bottom: 1px solid #F7F8FB
    +tr(0.3s)
    +h
      background: #F7F8FB
    a
      display: block
      font-size: 14px
      display: block
      position: relative
      color: #000
      +tr(0.3s)
      +h
        color: $accent
        text-decoration: none

.drop.--select
  .drop-overflow
    max-height: 125px

.--arrow
  .drop-input,
  .drop-button
    padding-right: 34px
    box-sizing: border-box
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23A3AED0' stroke-width='1.03' points='16 7 10 13 4 7'%3E%3C/polyline%3E%3C/svg%3E")
    background-repeat: no-repeat
    background-position: right 10px center
    background-size: 15px
  &._active,
  &._active-close
    .drop-input,
    .drop-button
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='%23A3AED0' stroke-width='1.03' points='4 13 10 7 16 13'%3E%3C/polyline%3E%3C/svg%3E")

.--search
  .drop-input
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 512 512' xml:space='preserve' fill='%23ccc'%3E%3Cpath d='M225.474,0C101.151,0,0,101.151,0,225.474c0,124.33,101.151,225.474,225.474,225.474 c124.33,0,225.474-101.144,225.474-225.474C450.948,101.151,349.804,0,225.474,0z M225.474,409.323 c-101.373,0-183.848-82.475-183.848-183.848S124.101,41.626,225.474,41.626s183.848,82.475,183.848,183.848 S326.847,409.323,225.474,409.323z'/%3E%3Cpath d='M505.902,476.472L386.574,357.144c-8.131-8.131-21.299-8.131-29.43,0c-8.131,8.124-8.131,21.306,0,29.43l119.328,119.328 c4.065,4.065,9.387,6.098,14.715,6.098c5.321,0,10.649-2.033,14.715-6.098C514.033,497.778,514.033,484.596,505.902,476.472z'/%3E%3C/svg%3E%0A")
    background-repeat: no-repeat
    background-position: right 14px center
    background-size: 15px
  &._active
    .drop-input,
    &._active-close
      background-image: none