.counter
  display: flex
  justify-content: flex-start
  align-items: center
  position: relative
  width: 100%
  max-width: 125px
  flex: 0 0 125px
  padding: 0 40px 0 0
  overflow: hidden
  box-sizing: border-box
  user-select: none
  background-color: #fff
  border: 2px solid #E1E3EA
  border-radius: 20px

  &__field
    -webkit-box-shadow: none
    width: 40px
    border: none
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    box-shadow: none
    user-select: none

    input
      -moz-appearance: textfield
      position: relative
      max-width: 40px
      height: 40px
      border: 0
      font-style: normal
      font-weight: normal
      font-size: 15px
      line-height: 130%
      color: #050505
      text-align: center
      user-select: none

      &:hover, &:focus, &:active
        outline: 0

      &[type=number]
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-buttons
          -webkit-appearance: none
          margin: 0

  &__btn
    display: flex
    justify-content: center
    align-items: center
    width: 30px
    height: 30px
    flex: 0 0 30px
    overflow: hidden
    border: none
    box-shadow: none
    user-select: none
    color: #fff
    cursor: pointer
    margin: 5px
    border-radius: 50%

    +h
      background: #F0F1F4

    &:after
      content: ''
      display: flex
      align-items: center
      justify-content: center
      top: 0
      right: 0
      bottom: 0
      left: 0
      width: 100%
      height: 100%
      border: none
      text-shadow: none
      background-repeat: no-repeat
      background-position: center
      background-size: 10px
      +tr(0.3s)

    &.minus
      &:after
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.583557 1.5351C0.583557 0.98281 1.03127 0.535095 1.58356 0.535095H7.58356C8.13584 0.535095 8.58356 0.98281 8.58356 1.5351C8.58356 2.08738 8.13584 2.5351 7.58356 2.5351H1.58356C1.03127 2.5351 0.583557 2.08738 0.583557 1.5351Z' fill='black'/%3E%3C/svg%3E%0A")

    &.plus
      &:after
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.583557 4.53511C0.583557 4.09328 0.941729 3.73511 1.38356 3.73511H7.78356C8.22539 3.73511 8.58356 4.09328 8.58356 4.53511C8.58356 4.97694 8.22539 5.33511 7.78356 5.33511H1.38356C0.941729 5.33511 0.583557 4.97694 0.583557 4.53511Z' fill='black'/%3E%3Cpath d='M4.58356 0.53511C5.02538 0.53511 5.38356 0.893283 5.38356 1.33511L5.38356 7.73511C5.38356 8.17694 5.02538 8.53511 4.58356 8.53511C4.14173 8.53511 3.78356 8.17694 3.78356 7.73511L3.78356 1.33511C3.78356 0.893282 4.14173 0.53511 4.58356 0.53511Z' fill='black'/%3E%3C/svg%3E%0A")