.action-btns
  display: flex
  justify-content: flex-end
  align-items: center
  position: relative

  .action-item
    display: block
    position: relative
    text-align: left
    z-index: 1
    & + .action-item
      margin-left: 20px
    &__btn
      display: flex
      justify-content: center
      align-items: center
      width: 45px
      height: 45px
      flex: 0 0 45px
      background: #FFF
      border-radius: 8px
      color: #CCD2E3
      font-size: 28px
      border-radius: 8px
      z-index: 2
      cursor: pointer
      box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
      +tr(0.3s)
      +h
        color: $accent
    &__content
      display: block
      position: absolute
      top: -10px
      right: -10px
      z-index: -1
      background: #FFFFFF
      border: 1.5px solid #E1E3EA
      box-sizing: border-box
      box-shadow: 0px 21px 38px -20px rgba(146, 157, 165, 0.3)
      border-radius: 8px 0px 8px 8px
      width: 275px
      padding: 15px 60px 15px 15px
      opacity: 0
      pointer-events: none
      +tr(0.3s)
      +r(767)
        width: 305px
        right: auto
        left: -2px
        top: -2px
        padding: 15px
        border-radius: 8px
    &__header
      display: block
      position: relative
      padding-bottom: 10px
      margin-bottom: 10px
      +r(767)
        display: flex
        align-items: center
        height: 30px
        padding-left: 50px
        margin-bottom: 20px
    &__title
      font-style: normal
      font-weight: 600
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #A3AED0
    &__body
      display: block
      position: relative
      ul
        list-style-type: none
        padding: 0
        margin: 0
        li
          display: block
          position: relative
          font-style: normal
          font-weight: 600
          font-size: 14px
          line-height: 110%
          letter-spacing: 0.02em
          color: #5A5D65
          & + li
            margin-top: 5px
          a
            display: inline-block
            font-style: normal
            font-weight: 600
            font-size: 14px
            line-height: 110%
            letter-spacing: 0.02em
            color: #5A5D65
            text-decoration: none
            +tr(0.3s)
            +h
              color: $accent

    &__footer
      margin-top: 10px

  .action-item
    +h
      z-index: 2
      .action-item__content
        opacity: 1
        pointer-events: all