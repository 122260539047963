.offcanvas-backdrop
  background: #5B6485
  mix-blend-mode: darken
  opacity: 0.6

.offcanvas
  background-image: url(../img/bg_offcanvas.svg)
  background-repeat: no-repeat
  background-position: right bottom
  +r(767)
    background-size: 70%

.offcanvas-end
  width: 450px
  border: none

.offcanvas-header
  padding: 30px

  .btn-close
    display: flex
    justify-content: center
    align-items: center
    width: 30px
    height: 30px
    background-color: #FFFFFF
    background-size: 10px
    box-shadow: 0px 4px 20px rgba(112, 144, 176, 0.2)
    border-radius: 8px
    padding: 0
    margin: 0

.offcanvas-title
  font-style: normal
  font-weight: 600
  font-size: 18px
  line-height: 100%
  letter-spacing: 0.02em
  color: #A3AED0

.offcanvas-body
  padding: 0 0 30px 0

  scrollbar-color: $accent #E2EBEC
  scrollbar-width: thin!important
  -moz-appearance: none !important

  &::-webkit-scrollbar
    width: 6px

  &::-webkit-scrollbar-track
    background-color: #E2EBEC

  &::-webkit-scrollbar-thumb
    background-color: $accent

.offcanvas-menu
  list-style-type: none
  padding: 0
  margin: 0
  li
    a
      display: flex
      align-items: center
      justify-content: flex-start
      position: relative
      padding: 16px 30px
      font-style: normal
      font-weight: 500
      font-size: 18px
      line-height: 100%
      letter-spacing: 0.02em
      color: #5B6485
      +tr(0.3s)
      +ico()
        font-size: 28px
        color: $accent
        margin-right: 15px
      &::before
        content: ''
        display: block
        width: 4px
        height: 100%
        background: #628EFF
        box-shadow: 1px 0px 4px 1px rgba(34, 131, 255, 0.3)
        position: absolute
        left: 0
        top: 0
        opacity: 0
        +tr(0.3s)
      +h
        background: #F7F8FB
        &::before
          opacity: 1

      &.active
        background: #F7F8FB
        &::before
          opacity: 1


  .nav-header
    background: #F7F8FB
    display: flex
    position: relative
    align-items: center
    justify-content: flex-start
    padding: 16px 30px
    color: #5B6485
    font-size: 20px
    font-style: normal
    font-weight: 500
    letter-spacing: 0.02em
    line-height: 100%
    transition: all 0.3s linear
    &::before
      display: block
      position: absolute
      top: 0
      left: 0
      width: 4px
      height: 100%
      background: red
      box-shadow: 1px 0px 4px 1px rgba(lighten(red, 30%), 30%)
      content: ''
      opacity: 1
      transition: all 0.3s linear