// 100 Extra Light or Ultra Light;
// 200 Light or Thin;
// 300 Book or Demi;
// 400 Regular or Normal;
// 500 Medium;
// 600 Semibold or Demibold;
// 700 Bold;
// 800 Black or Extra Bold or Heavy;
// 900 Extra Black or Fat or Ultra Black


[class^="ico_"],
[class*=" ico_"]
  display: inline-flex
  align-items: center
  line-height: 1em

html,
body
  font-family: $font-base
  font-style: normal
  font-size: 16px
  line-height: 100%
  letter-spacing: 0.02em
  +fz(16, 13)

h1, h2, h3, h4, h5, h6
  font-family: $font-head
  font-weight: 700