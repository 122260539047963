.widjet
  &.--orders
    background-image: url(../img/bg_widjet-orders.svg)
    background-repeat: no-repeat
    background-position: left bottom
    +r(1399)
      background-image: none
    .search
      margin-top: 100px
      margin-bottom: 20px
      +r(1399)
        margin: 0
        width: calc(50% - 20px)
      +r(575)
        margin-bottom: 10px!important
    .widjet__head
      +r(1399)
        align-items: center
      +r(767)
        flex-wrap: wrap
      .widjet__title
        +r(767)
          order: 1
      .widjet__others
        +r(767)
          order: 3
          width: 100%
          margin: 16px -10px 0 -10px
        +r(575)
          margin: 16px 0 0 0
      .widjet__action
        +r(767)
          order: 2

    .js-page-size
      display: none

    .widjet__others
      flex-grow: 1
      margin: 0 20px
      ._dynamic_adapt_
        display: flex
        align-items: center
        +r(575)
          flex-direction: column
        & > *
          +r(1399)
            width: calc(100% - 20px)
            margin: 0 10px
          +r(575)
            width: 100%
            margin: 0