.chat
  position: fixed
  right: 20px
  bottom: 20px
  z-index: 100
  +r(767)
    right: 15px
    bottom: 15px

.chat-btn
  display: flex
  justify-content: center
  align-items: center
  color: #fff
  font-size: 25px
  width: 55px
  height: 54px
  background: linear-gradient(0deg, #628EFF, #628EFF),#628EFF
  border-radius: 50%
  position: relative
  z-index: 1
  cursor: pointer
  &__numb
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 24px
    background: #FF6A6A
    border-radius: 15px
    position: absolute
    top: 0
    right: 0
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 14px
    text-align: center
    letter-spacing: 0.02em
    color: #FFFFFF

.chat-box
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  width: 320px
  height: 430px
  background: #FFFFFF
  box-shadow: 0px 4px 20px 3px rgba(91, 100, 133, 0.2)
  border-radius: 8px 8px 0px 0px
  position: absolute
  right: 0
  bottom: 0
  z-index: 2
  +tr(0.3s)

  transform: translateY(50px)
  pointer-events: none
  visibility: hidden
  opacity: 0

  &.is-show
    transform: translateY(0)
    pointer-events: all
    visibility: visible
    opacity: 1

  &__head
    display: flex
    justify-content: space-between
    align-items: center
    background: #5B6485
    border-radius: 8px 8px 0px 0px
    padding: 15px
  &__close
    font-size: 16px
    color: rgba(204, 210, 227, 0.5)
    cursor: pointer
  &__body
    display: block
    position: relative
    padding: 15px 0 0 15px
    flex-grow: 1
    &:after
      content: ''
      display: block
      position: absolute
      left: 0
      bottom: 0
      width: calc(100% - 5px)
      height: 30px
      background: linear-gradient(180deg, #C4C4C4 0%, rgba(255, 255, 255, 0) 0.01%, #FFFFFF 100%)
  &__overflow
    padding-right: 15px
    padding-bottom: 30px
    height: 275px
    overflow: auto
    scrollbar-color: #CCD2E3 #F7F8FB
    scrollbar-width: thin!important
    -moz-appearance: none !important
    &::-webkit-scrollbar
      width: 4px
    &::-webkit-scrollbar-track
      background-color: #F7F8FB
    &::-webkit-scrollbar-thumb
      background-color: #CCD2E3
  &__foot
    border-top: 1px solid #F7F8FB
    min-height: 70px
    padding: 15px

.manager
  display: flex
  justify-content: flex-start
  align-items: center
  &__avatar
    width: 35px
    height: 35px
    flex: 0 0 35px
    border-radius: 50%
    overflow: hidden
    margin-right: 10px
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__info
    display: block
    position: relative
  &__name
    font-style: normal
    font-weight: 600
    font-size: 16px
    line-height: 19px
    letter-spacing: 0.02em
    color: #FFFFFF
  &__position
    font-style: normal
    font-weight: 600
    font-size: 12px
    line-height: 14px
    letter-spacing: 0.02em
    color: #A3AED0


.chat-form
  display: flex
  justify-content: space-between
  align-items: flex-start
  position: relative
.chat-form-upload
  .file-upload
    opacity: 0
    z-index: -1
    position: absolute
  label
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    color: #CCD2E3
    background: #fff
    box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
    border-radius: 8px
.chat-form-input
  flex-grow: 1
  margin: 0 10px
  textarea
    width: 100%
    min-height: 20px
    border: none
    resize: none
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
    overflow: auto

    +placeholder()
      font-style: normal
      font-weight: 500
      font-size: 14px
      line-height: 35px
      letter-spacing: 0.02em
      color: #A3AED0
    &:focus
      +placeholder()
        opacity: 0

  textarea.auto-expand
    max-height: 35px
    scrollbar-color: #CCD2E3 #F7F8FB
    scrollbar-width: thin!important
    -moz-appearance: none !important
    &::-webkit-scrollbar
      width: 4px
    &::-webkit-scrollbar-track
      background-color: #F7F8FB
    &::-webkit-scrollbar-thumb
      background-color: #CCD2E3

.file-upload-box
  display: none
  align-items: center
  justify-content: space-between
  position: absolute
  top: 0
  left: 45px
  z-index: 1
  background-color: #fff
  width: 200px
  height: 35px
  padding: 0 5px
  &.is-active
    display: flex
  .file-upload-name
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #5B6485
    +line(1)
  .ico_close
    font-size: 16px
    color: #FF6A6A
    cursor: pointer

.chat-form-btn
  button
    width: 35px
    height: 35px
    color: #fff
    font-size: 12px
    border: none
    background-color: $accent
    border-radius: 8px

.chat-item
  display: block
  position: relative
  & + .chat-item
    margin-top: 10px
  &__box
    display: flex
    justify-content: flex-start
    align-items: flex-start
  &__avatar
    width: 32px
    height: 32px
    flex: 0 0 32px
    margin-right: 5px
    border-radius: 50%
    overflow: hidden
    img
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
  &__info
    display: block
    position: relative
  &__name
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #A3AED0
    margin-bottom: 5px
  &__text
    display: block
    position: relative
    padding: 8px 40px 8px 10px
    background: #F7F8FB
    border-radius: 8px
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 17px
    letter-spacing: 0.02em
    color: #5B6485
    p
      margin: 0
    & + .chat-item__text
      margin-top: 5px
    .time
      display: block
      position: absolute
      right: 10px
      bottom: 8px
      text-align: right
      font-style: normal
      font-weight: 600
      font-size: 11px
      line-height: 100%
      letter-spacing: 0.02em
      color: rgba(91, 100, 133, 0.5)
      margin-top: 5px
  &__file
    display: inline-flex
    align-items: center
    margin-top: 5px
    padding: 8px 10px
    background: #F7F8FB
    border-radius: 8px
  &__file-icon
    display: flex
    justify-content: center
    align-items: center
    width: 35px
    height: 35px
    flex: 0 0 35px
    background: #fff
    box-shadow: 0px 2px 8px rgba(204, 210, 227, 0.6)
    margin-right: 8px
    border-radius: 8px
    color: $accent
    text-align: center
  &__file-info
    display: block
    position: relative
  &__file-title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 115%
    letter-spacing: 0.02em
    color: #5B6485
  &__file-bottom
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 5px
  &__file-size
    font-style: normal
    font-weight: 600
    font-size: 11px
    line-height: 115%
    color: #A3AED0
  &__file-time
    font-style: normal
    font-weight: 600
    font-size: 11px
    line-height: 100%
    text-align: right
    letter-spacing: 0.02em
    color: rgba(91, 100, 133, 0.5)

  &.--manager
    .chat-item__text,
    .chat-item__file
      background: rgba(163, 174, 208, 0.25)