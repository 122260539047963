.widjet.--receivables
  position: relative
  &::before
    content: ''
    display: block
    position: absolute
    right: 30px
    bottom: 10px
    background-image: url(../img/decor_widjet-receivables.svg)
    width: 133px
    height: 172px
    z-index: 1
    +r(767)
      right: 5px

  .widjet__head
    +r(575)
      flex-direction: column
      .widjet__action
        margin-left: auto
        margin-top: 10px

.receivable-card
  position: relative
  +r(767)
    margin-bottom: 40px
  &::before
    content: ''
    display: block
    position: absolute
    top: 10px
    right: 0
    width: 90%
    height: 100%
    background: linear-gradient(101.27deg, rgba(71, 143, 250, 0.5) 5.33%, rgba(121, 213, 141, 0.5) 101.23%)
    filter: blur(20px)
    border-radius: 20px
    z-index: 1
  &__box
    background: url(../img/bg_widjet-receivables.svg), linear-gradient(101.27deg, #478FFA 5.33%, #79D58D 101.23%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(94.34deg, #6F9BF3 0%, #628EFF 96.55%)
    border-radius: 20px
    background-position: right bottom
    background-repeat: no-repeat
    background-size: contain
    position: relative
    z-index: 2
  &__head
    display: flex
    justify-content: space-between
    align-items: center
    padding: 15px 30px
    +r(767)
      padding: 15px 10px
    & > div:first-child
      display: flex
      align-items: center
  &__numb
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 100%
    color: #FFFFFF
    margin-right: 10px
    +r(767)
      font-size: 13px
  &__date
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF
    +r(767)
      font-size: 11px
  &__days
    font-style: normal
    font-weight: 500
    font-size: 12px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF
    +r(767)
      font-size: 11px
    strong
      margin-left: 9px
      display: inline-block
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      text-align: right
      color: #FFFFFF
      +r(767)
        font-size: 13px
  &__body
    padding: 30px
    border-top: 2px solid rgba(255, 255, 255, 0.4)
    display: flex
    justify-content: space-between
    +r(413)
      flex-direction: column
      padding: 10px 30px
  &__item
    display: block
    position: relative
    margin: 0 10px
    +r(413)
      margin: 10px 0
  &__sum
    font-style: normal
    font-weight: bold
    font-size: 32px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF
    margin-bottom: 5px
    sup
      font-style: normal
      font-weight: 600
      font-size: 14px
      line-height: 100%
      letter-spacing: 0.02em
      color: #FFFFFF
      top: -21px
  &__label
    font-style: normal
    font-weight: 600
    font-size: 14px
    line-height: 100%
    letter-spacing: 0.02em
    color: #FFFFFF


.receivable-progress
  display: block
  position: relative
  max-width: 190px
  +r(767)
    max-width: 160px
  & + .receivable-progress
    margin-top: 20px
    .receivable-progress__line-value
      background: linear-gradient(274.35deg, #FF6A6A -4.93%, #7A64BB 141.33%), linear-gradient(274.47deg, #7097EF -4.92%, #7A64BB 102.55%)

  &__head
    display: flex
    justify-content: space-between
    align-items: center
  &__title
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    letter-spacing: 0.02em
    color: #A3AED0
  &__days
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 16px
    text-align: right
    color: #FF6A6A
  &__value
    margin: 5px 0
    font-style: normal
    font-weight: 600
    font-size: 24px
    line-height: 29px
    letter-spacing: 0.02em
    color: #5B6485
  &__line
    width: 100%
    height: 10px
    background: #EBF2FF
    position: relative
  &__line-value
    display: block
    position: absolute
    top: 0
    left: 0
    height: 10px
    background: linear-gradient(274.47deg, #72CB9D -4.92%, #4890FA 102.55%)