// Vars

$maxWidth: 1920

$font-base : 'Gilroy'
$font-head : 'Gilroy'

$base      : #5B6485
$base2     : #A3AED0
$accent    : #628EFF
$light-grey: #F7F8FB