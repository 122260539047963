.widjet
  &.--orders-2
    background-position: left -5% bottom

    .widjet__head
      align-items: center

    .search
      margin: 0
      width: calc(50% - 20px)
      +r(575)
        margin-bottom: 10px!important

    .widjet__others
      flex-grow: 1
      margin: 0 20px 0 40px
      display: flex
      align-items: center
      max-width: 600px
      margin-right: auto
      +r(767)
        justify-content: space-between
        max-width: 100%
        margin: 16px 0 0 0!important
      +r(575)
        flex-direction: column
      & > *
        width: calc(50% - 20px)
        margin: 0 10px
        +r(575)
          width: 100%
          margin: 0

    .btn-status.confirmed
      span:last-child
        color: #5B6485