.page-receivables
  .table-filter
    .col-md-12
      +r(1199)
        text-align: right
      .btn
        +r(575)
          width: 100%


.receivable-box
  background: #FFFFFF
  border-radius: 8px
  padding: 30px
  +r(767)
    padding: 15px
  &__select
    margin-bottom: 30px
    .drop
      max-width: 300px
      +r(767)
        max-width: 100%
  &__list
    ul
      list-style: none
      padding: 0
      margin: 0
      li
        display: flex
        justify-content: flex-start
        align-items: center
        padding: 15px 10px
        border-bottom: 1px solid #F7F8FB

        & > *:first-child
          width: 40%
          font-style: normal
          font-weight: 500
          font-size: 14px
          line-height: 100%
          letter-spacing: 0.02em
          color: #5B6485
          padding-right: 10px
          +fz(14, 12)
          +r(767)
            width: 60%

        & > *:last-child
          width: 60%
          font-style: normal
          font-weight: 600
          font-size: 16px
          line-height: 100%
          letter-spacing: 0.04em
          color: #5B6485
          padding-left: 10px
          +fz(16, 14)
          +r(767)
            width: 40%

          &.accent
            color: #FF6A6A

        & > a:last-child
          color: #628EFF

  &__btn
    margin-top: 30px
    .btn
      +r(767)
        width: 100%